import {
  ADD_NEW_COLOR,
  ADD_NEW_COLOR_SUCCESS,
  ADD_NEW_COLOR_FAIL,
  GET_COLORS_LIST,
  GET_COLORS_LIST_SUCCESS,
  GET_COLORS_LIST_FAIL,
  UPDATE_COLOR,
  UPDATE_COLOR_SUCCESS,
  UPDATE_COLOR_FAIL,
  DELETE_COLOR,
  DELETE_COLOR_SUCCESS,
  DELETE_COLOR_FAIL,
  GET_COLOR_DETAIL,
  GET_COLOR_DETAIL_SUCCESS,
  GET_COLOR_DETAIL_FAIL,
} from "./actionTypes"

export const getColorsList = () => ({
  type: GET_COLORS_LIST,
})

export const getColorsListSuccess = colors => ({
  type: GET_COLORS_LIST_SUCCESS,
  payload: colors,
})

export const getColorsListFail = error => ({
  type: GET_COLORS_LIST_FAIL,
  payload: error,
})

export const getColorDetail = colorId => ({
  type: GET_COLOR_DETAIL,
  colorId,
})

export const getColorDetailSuccess = colorDetails => ({
  type: GET_COLOR_DETAIL_SUCCESS,
  payload: colorDetails,
})

export const getColorDetailFail = error => ({
  type: GET_COLOR_DETAIL_FAIL,
  payload: error,
})

export const addNewColor = (color, history) => ({
  type: ADD_NEW_COLOR,
  payload: { color, history },
})

export const addNewColorSuccess = color => ({
  type: ADD_NEW_COLOR_SUCCESS,
  payload: color,
})

export const addNewColorFail = error => ({
  type: ADD_NEW_COLOR_FAIL,
  payload: error,
})

export const updateColor = (id, color, history) => ({
  type: UPDATE_COLOR,
  payload: { id, color, history },
})

export const updateColorSuccess = color => ({
  type: UPDATE_COLOR_SUCCESS,
  payload: color,
})

export const updateColorFail = error => ({
  type: UPDATE_COLOR_FAIL,
  payload: error,
})

export const deleteColor = color => ({
  type: DELETE_COLOR,
  color,
})

export const deleteColorSuccess = color => ({
  type: DELETE_COLOR_SUCCESS,
  payload: color,
})

export const deleteColorFail = error => ({
  type: DELETE_COLOR_FAIL,
  payload: error,
})
