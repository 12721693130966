import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_NEWSLETTER_LIST } from "./actionTypes"
import { getNewsletterListFail, getNewsletterListSuccess } from "./actions"
import { getNewsletterList } from "helpers/fakebackend_helper"
import { toast } from "react-toastify"

function* fetchNewsLetters() {
  try {
    const response = yield call(getNewsletterList)
    yield put(getNewsletterListSuccess(response))
  } catch (error) {
    yield put(getNewsletterListFail(error))
  }
}

function* newsLetterSaga() {
  yield takeEvery(GET_NEWSLETTER_LIST, fetchNewsLetters)
}

export default newsLetterSaga
