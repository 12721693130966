import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  addNewSubCategory,
  getCategoriesListForSubCategory,
} from "store/actions"
import { uploadFileSubcategory } from "helpers/fakebackend_helper"
import { useSelector } from "react-redux"
import Swal from "sweetalert2"

const AddSubCategory = props => {
  //meta title
  document.title = "Add Subcategory"
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCategoriesListForSubCategory())
  }, [dispatch])

  const dataState = state => state.subcategories.categoryList.data
  const data = useSelector(dataState) || []

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    // enableReinitialize: true,
    initialValues: {
      categoryId: "",
      subcategory_name: "",
      description: "",
      full_pic: "",
      thumbnail_pic: "",
      full_pic_mobile: "",
      thumbnail_pic_mobile: "",
      is_active: true,
    },

    validationSchema: Yup.object({
      categoryId: Yup.string().required("Please Select Category"),
      subcategory_name: Yup.string().required("Please Enter Subcategory Name"),
      description: Yup.string().required("Please Enter Description"),
    }),

    onSubmit: async values => {
      let thumbnail_pic,
        full_pic,
        thumbnail_pic_mobile,
        full_pic_mobile,
        loadingSwal

      try {
        loadingSwal = Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
        })

        try {
          thumbnail_pic = await uploadFileSubcategory(values.thumbnail_pic)
        } catch (error) {
          toast.error(error.response.data.msg)
          loadingSwal.close()
          throw error
        } finally {
          loadingSwal.close()
        }

        try {
          full_pic = await uploadFileSubcategory(values.full_pic)
        } catch (error) {
          toast.error(error.response.data.msg)
          loadingSwal.close()
          throw error
        } finally {
          loadingSwal.close()
        }

        try {
          thumbnail_pic_mobile = await uploadFileSubcategory(
            values.thumbnail_pic_mobile
          )
        } catch (error) {
          toast.error(error.response.data.msg)
          loadingSwal.close()
          throw error
        } finally {
          loadingSwal.close()
        }

        try {
          full_pic_mobile = await uploadFileSubcategory(values.full_pic_mobile)
        } catch (error) {
          toast.error(error.response.data.msg)
          loadingSwal.close()
          throw error
        } finally {
          loadingSwal.close()
        }

        const value = {
          categoryId: values.categoryId || "",
          subcategory_name: values.subcategory_name || "",
          description: values.description || "",
          full_pic: (full_pic && full_pic.data[0]) || "",
          thumbnail_pic: (thumbnail_pic && thumbnail_pic.data[0]) || "",
          full_pic_mobile: (full_pic_mobile && full_pic_mobile.data[0]) || "",
          thumbnail_pic_mobile:
            (thumbnail_pic_mobile && thumbnail_pic_mobile.data[0]) || "",
          is_active: values.is_active || true,
        }

        const response = await dispatch(
          addNewSubCategory(value, props.router.navigate)
        )
        if (response.result) {
          loadingSwal.close()
        }
      } catch (error) {
        loadingSwal.close()
        console.error(error)
      } finally {
        loadingSwal.close()
      }
    },
  })

  const [thumbnailFile, setThumbnailFile] = useState([])
  const [thumbnailFileMobile, setThumbnailFileMobile] = useState([])
  const [fullPic, setFullPic] = useState([])
  const [fullPicMobile, setFullPicMobile] = useState([])

  function handleAcceptedFiles(value, files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    if (value === "ThumbnailWeb") {
      setThumbnailFile(files)
    } else if (value === "FullPic") {
      setFullPic(files)
    } else if (value === "ThumbnailMobile") {
      setThumbnailFileMobile(files)
    } else if (value === "FullPicMobile") {
      setFullPicMobile(files)
    }
  }

  function handleDeletedFiles(value, fileToDelete) {
    if (value === "ThumbnailWeb") {
      setThumbnailFile(prevFiles =>
        prevFiles.filter(file => file !== fileToDelete)
      )
    } else if (value === "FullPic") {
      setFullPic(prevFiles => prevFiles.filter(file => file !== fileToDelete))
    } else if (value === "ThumbnailMobile") {
      setThumbnailFileMobile(prevFiles =>
        prevFiles.filter(file => file !== fileToDelete)
      )
    } else if (value === "FullPicMobile") {
      setFullPicMobile(prevFiles =>
        prevFiles.filter(file => file !== fileToDelete)
      )
    }
  }

  useEffect(() => {
    validation.setFieldValue("thumbnail_pic", thumbnailFile)
  }, [thumbnailFile])

  useEffect(() => {
    validation.setFieldValue("thumbnail_pic_mobile", thumbnailFileMobile)
  }, [thumbnailFileMobile])

  useEffect(() => {
    validation.setFieldValue("full_pic", fullPic)
  }, [fullPic])

  useEffect(() => {
    validation.setFieldValue("full_pic_mobile", fullPicMobile)
  }, [fullPicMobile])

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CardTitle className="mb-2 font-size-18">
            <Link to="/list-subcategories" style={{ color: "black" }}>
              <i className="mdi mdi-arrow-left font-size-18 me-2"></i>
            </Link>
            Add Subcategory
          </CardTitle>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="formrow-category-Input">
                            Category*
                          </Label>
                          <select
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            name="categoryId"
                            value={validation.values.categoryId}
                            className={`form-select ${
                              validation.touched.categoryId &&
                              validation.errors.categoryId
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {!validation.values.categoryId && (
                              <option>Select Category</option>
                            )}

                            {(data || []).map(item => (
                              <option key={item.id} value={item._id}>
                                {item.category_name}
                              </option>
                            ))}
                          </select>
                          {validation.touched.categoryId &&
                          validation.errors.categoryId ? (
                            <FormFeedback type="invalid">
                              {validation.errors.categoryId}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="category">Subcategory Name*</Label>
                          <Input
                            id="subcategory_name"
                            name="subcategory_name"
                            type="text"
                            className="form-control"
                            placeholder="Enter Subcategory Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.subcategory_name &&
                              validation.errors.subcategory_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.subcategory_name &&
                          validation.errors.subcategory_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.subcategory_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Description*</Label>
                          <Input
                            type="textarea"
                            className="form-control mb-3"
                            id="description"
                            placeholder="Enter Description"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.description &&
                              validation.errors.description
                                ? true
                                : false
                            }
                          />
                          {validation.touched.description &&
                          validation.errors.description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.description}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <Label htmlFor="is_active">Is Active</Label>
                        </div>
                        <Col lg="12">
                          <div className="form-check form-check-inline">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="is_active"
                              name="is_active"
                              onBlur={validation.handleBlur}
                              onClick={e => {
                                validation.setFieldValue(
                                  "is_active",
                                  !e.target.checked
                                )
                              }}
                              checked={Boolean(validation?.values?.is_active)}
                            />
                            <Label
                              className="form-check-Label"
                              htmlFor="is_active"
                            >
                              Yes
                            </Label>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>Choose Thumbnail Picture</Label>
                        <Form>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles("ThumbnailWeb", acceptedFiles)
                            }}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {thumbnailFile.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                      <Col className="text-end">
                                        <Button
                                          className="btn btn-danger"
                                          onClick={() => {
                                            handleDeletedFiles(
                                              "ThumbnailWeb",
                                              f
                                            )
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </Form>
                      </Col>
                      <Col>
                        <Label>Choose Full Picture</Label>
                        <Form>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles("FullPic", acceptedFiles)
                            }}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {fullPic.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                      <Col className="text-end">
                                        <Button
                                          className="btn btn-danger"
                                          onClick={() => {
                                            handleDeletedFiles("FullPic", f)
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </Form>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>Choose Thumbnail Picture Mobile</Label>
                        <Form>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(
                                "ThumbnailMobile",
                                acceptedFiles
                              )
                            }}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {thumbnailFileMobile.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                      <Col className="text-end">
                                        <Button
                                          className="btn btn-danger"
                                          onClick={() => {
                                            handleDeletedFiles(
                                              "ThumbnailMobile",
                                              f
                                            )
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </Form>
                      </Col>
                      <Col>
                        <Label>Choose Full Picture Mobile</Label>
                        <Form>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(
                                "FullPicMobile",
                                acceptedFiles
                              )
                            }}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message needsclick">
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {fullPicMobile.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                      <Col className="text-end">
                                        <Button
                                          className="btn btn-danger"
                                          onClick={() => {
                                            handleDeletedFiles(
                                              "FullPicMobile",
                                              f
                                            )
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </Form>
                      </Col>
                    </Row>
                    <Row className="align-items-center mt-3">
                      <Col className="text-end">
                        <button
                          className="w-md "
                          type="submit"
                          style={{
                            backgroundColor: "#082a6b",
                            color: "#ffffff",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            height: "35px",
                          }}
                        >
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddSubCategory)

AddSubCategory.propTypes = {
  history: PropTypes.object,
}
