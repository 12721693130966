import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Col, FormFeedback, Input, Label, Modal, Row } from "reactstrap"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { addTrackingDetail } from "store/actions"
// import { paymentAmountList } from "store/actions"

const trackOrderDetailModal = ({ isOpen, toggle, orderId }) => {
  const dispatch = useDispatch()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const userName = user?.data.user_name
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    // enableReinitialize: true,
    initialValues: {
      orderId: orderId,
      status: "DISPATCHED",
      deliveryPartner: "",
      trackingId: "",
      createdBy: userName || "",
      trackingSite: "",
    },

    validationSchema: Yup.object({
      deliveryPartner: Yup.string().required(
        "Please Enter Delivery Partner Name"
      ),
      trackingId: Yup.string().required("Please Enter Tracking Id"),
      trackingSite: Yup.string().required("Please Enter Tracking Site URL"),
    }),

    onSubmit: async values => {
      dispatch(addTrackingDetail(values, toggle))
    },
  })

  //   useEffect(() => {
  //     dispatch(paymentAmountList({ filter: filterResponse }))
  //   }, [filterResponse, dispatch])

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Add Track Order Details
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col sm="6">
            <div className="mb-3">
              <Label htmlFor="deliveryPartner">Delivery Partner*</Label>
              <Input
                id="deliveryPartner"
                name="deliveryPartner"
                type="text"
                className="form-control"
                placeholder="Delivery Partner"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.deliveryPartner &&
                  validation.errors.deliveryPartner
                    ? true
                    : false
                }
              />
              {validation.touched.deliveryPartner &&
              validation.errors.deliveryPartner ? (
                <FormFeedback type="invalid">
                  {validation.errors.deliveryPartner}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col sm="6">
            <div className="mb-3">
              <Label htmlFor="trackingId">Tracking Id*</Label>
              <Input
                id="trackingId"
                name="trackingId"
                type="text"
                className="form-control"
                placeholder="Tracking Id"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.trackingId && validation.errors.trackingId
                    ? true
                    : false
                }
              />
              {validation.touched.trackingId && validation.errors.trackingId ? (
                <FormFeedback type="invalid">
                  {validation.errors.trackingId}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <div className="mb-3">
              <Label htmlFor="trackingSite">Tracking Site*</Label>
              <Input
                id="trackingSite"
                name="trackingSite"
                type="text"
                className="form-control"
                placeholder="Tracking Site"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.trackingSite &&
                  validation.errors.trackingSite
                    ? true
                    : false
                }
              />
              {validation.touched.trackingSite &&
              validation.errors.trackingSite ? (
                <FormFeedback type="invalid">
                  {validation.errors.trackingSite}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row className="align-items-center mt-3">
          <Col className="text-end">
            <button
              className="w-md "
              type="submit"
              onClick={validation.handleSubmit}
              style={{
                backgroundColor: "#082a6b",
                color: "#ffffff",
                borderTopRightRadius: "10px",
                borderBottomLeftRadius: "10px",
                height: "35px",
              }}
            >
              Submit
            </button>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default trackOrderDetailModal
