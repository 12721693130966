import {
  ADD_NEW_SIZE_CHART,
  ADD_NEW_SIZE_CHART_FAIL,
  ADD_NEW_SIZE_CHART_SUCCESS,
  DELETE_SIZE_CHART,
  DELETE_SIZE_CHART_FAIL,
  DELETE_SIZE_CHART_SUCCESS,
  GET_SIZES_FOR_SIZE_CHART,
  GET_SIZES_FOR_SIZE_CHART_FAIL,
  GET_SIZES_FOR_SIZE_CHART_SUCCESS,
  GET_SIZE_CHART_DETAIL,
  GET_SIZE_CHART_DETAIL_FAIL,
  GET_SIZE_CHART_DETAIL_SUCCESS,
  GET_SIZE_CHART_LIST,
  GET_SIZE_CHART_LIST_FAIL,
  GET_SIZE_CHART_LIST_SUCCESS,
  UPDATE_SIZE_CHART,
  UPDATE_SIZE_CHART_FAIL,
  UPDATE_SIZE_CHART_SUCCESS,
} from "./actionTypes"

export const getSizeChartList = () => ({
  type: GET_SIZE_CHART_LIST,
})

export const getSizeChartListSuccess = sizes => ({
  type: GET_SIZE_CHART_LIST_SUCCESS,
  payload: sizes,
})

export const getSizeChartListFail = error => ({
  type: GET_SIZE_CHART_LIST_FAIL,
  payload: error,
})

export const getSizeChartDetail = sizeId => ({
  type: GET_SIZE_CHART_DETAIL,
  sizeId,
})

export const getSizeChartDetailSuccess = sizeDetails => ({
  type: GET_SIZE_CHART_DETAIL_SUCCESS,
  payload: sizeDetails,
})

export const getSizeChartDetailFail = error => ({
  type: GET_SIZE_CHART_DETAIL_FAIL,
  payload: error,
})

export const addNewSizeChart = (size, history) => ({
  type: ADD_NEW_SIZE_CHART,
  payload: { size, history },
})

export const addNewSizeChartSuccess = size => ({
  type: ADD_NEW_SIZE_CHART_SUCCESS,
  payload: size,
})

export const addNewSizeChartFail = error => ({
  type: ADD_NEW_SIZE_CHART_FAIL,
  payload: error,
})

export const updateSizeChart = (id, size, history) => ({
  type: UPDATE_SIZE_CHART,
  payload: { id, size, history },
})

export const updateSizeChartSuccess = size => ({
  type: UPDATE_SIZE_CHART_SUCCESS,
  payload: size,
})

export const updateSizeChartFail = error => ({
  type: UPDATE_SIZE_CHART_FAIL,
  payload: error,
})

export const deleteSizeChart = size => ({
  type: DELETE_SIZE_CHART,
  size,
})

export const deleteSizeChartSuccess = size => ({
  type: DELETE_SIZE_CHART_SUCCESS,
  payload: size,
})

export const deleteSizeChartFail = error => ({
  type: DELETE_SIZE_CHART_FAIL,
  payload: error,
})

export const getSizesForSizeChart = () => ({
  type: GET_SIZES_FOR_SIZE_CHART,
})

export const getSizesForSizeChartSuccess = sizes => ({
  type: GET_SIZES_FOR_SIZE_CHART_SUCCESS,
  payload: sizes,
})

export const getSizesForSizeChartFail = error => ({
  type: GET_SIZES_FOR_SIZE_CHART_FAIL,
  payload: error,
})
