import {
  ADD_NEW_SIZE_CHART_FAIL,
  ADD_NEW_SIZE_CHART_SUCCESS,
  DELETE_SIZE_CHART_FAIL,
  DELETE_SIZE_CHART_SUCCESS,
  GET_SIZES_FOR_SIZE_CHART_FAIL,
  GET_SIZES_FOR_SIZE_CHART_SUCCESS,
  GET_SIZE_CHART_DETAIL_FAIL,
  GET_SIZE_CHART_DETAIL_SUCCESS,
  GET_SIZE_CHART_LIST_FAIL,
  GET_SIZE_CHART_LIST_SUCCESS,
  UPDATE_SIZE_CHART_FAIL,
  UPDATE_SIZE_CHART_SUCCESS,
} from "./actionTypes"

const initialState = {
  sizeList: [],
  sizes: [],
  sizeForChart: [],
  sizeDetail: {},
  error: {},
  loading: false,
}

const sizeChart = (state = initialState, action) => {
  switch (action.type) {
    case GET_SIZE_CHART_LIST_SUCCESS:
      return {
        ...state,
        sizeList: action.payload,
        loading: false,
      }

    case GET_SIZE_CHART_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SIZE_CHART_DETAIL_SUCCESS:
      return {
        ...state,
        sizeDetail: action.payload,
        loading: false,
      }

    case GET_SIZE_CHART_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NEW_SIZE_CHART_SUCCESS:
      return {
        ...state,
        sizes: [...state.sizes, action.payload],
        loading: false,
      }

    case ADD_NEW_SIZE_CHART_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_SIZE_CHART_SUCCESS:
      return {
        ...state,
        sizeDetail: {
          ...state.sizeDetail,
          data: {
            ...state.sizeDetail.data,
            [action.payload.data._id]: {
              ...state.sizeDetail.data[action.payload.data._id],
              ...action.payload.data,
            },
          },
        },
      }

    case UPDATE_SIZE_CHART_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SIZE_CHART_SUCCESS:
      return {
        ...state,
        sizeList: state.sizeList.data.filter(
          size => size._id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_SIZE_CHART_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SIZES_FOR_SIZE_CHART_SUCCESS:
      return {
        ...state,
        sizeForChart: action.payload,
        loading: false,
      }

    case GET_SIZES_FOR_SIZE_CHART_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default sizeChart
