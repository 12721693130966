import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  ADD_TRACKING_DETAIL,
  GET_ALL_ORDERS,
  GET_DELIVERED_ORDERS,
} from "./actionTypes"
import {
  addTrackingDetailFail,
  addTrackingDetailSuccess,
  getAllOrdersListFail,
  getAllOrdersListSuccess,
  getDeliveredOrdersListFail,
  getDeliveredOrdersListSuccess,
} from "./actions"
import { addTrackingDetail, getAllOrdersList, getDeliveredOrdersList } from "helpers/fakebackend_helper"
import Swal from "sweetalert2"

//Include Both Helper File with needed methods

function* fetchAllOrders({ id }) {
  try {
    const response = yield call(getAllOrdersList, id)
    yield put(getAllOrdersListSuccess(response))
  } catch (error) {
    yield put(getAllOrdersListFail(error))
  }
}

function* onAddTrackingDetail({ payload: { data, toggle, history } }) {
  try {
    const response = yield call(addTrackingDetail, data)
    yield put(addTrackingDetailSuccess(response))
    if (response.result === 1) {
      const response2 = yield call(getAllOrdersList, data.orderId)
      if (response2.result === 1) {
        yield put(getAllOrdersListSuccess(response2))
      }
      toggle()
    }
  } catch (error) {
    yield put(addTrackingDetailFail(error))
  }
}

function* fetchDeliveredOrders({ id }) {
  try {
    const response = yield call(getDeliveredOrdersList, id)
    yield put(getDeliveredOrdersListSuccess(response))
  } catch (error) {
    yield put(getDeliveredOrdersListFail(error))
  }
}

function* ordersSaga() {
  yield takeEvery(GET_ALL_ORDERS, fetchAllOrders)
  yield takeEvery(GET_DELIVERED_ORDERS, fetchDeliveredOrders)
  yield takeEvery(ADD_TRACKING_DETAIL, onAddTrackingDetail)
}

export default ordersSaga
