import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Modal, Table } from "reactstrap"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { billAmountList } from "store/actions"

const businessAmount = ({ isOpen, toggle, data }) => {
  const formatNumberWithCommas = number => {
    if (!number) {
      return number
    }

    let numStr = String(number)

    let parts = numStr.split(".")

    let integerPart = parts[0]
    let lastThreeDigits = integerPart.slice(-3)
    let remainingDigits = integerPart.slice(0, -3)
    let formattedInteger =
      remainingDigits !== ""
        ? remainingDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          lastThreeDigits
        : lastThreeDigits

    return formattedInteger
  }
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Business
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <SimpleBar style={{ maxHeight: "60vh", overflowX: "auto" }}>
          <div style={{ minWidth: "100%" }}>
            <Table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Order No.</th>
                  <th>Full Name</th>
                  <th>Phone Number</th>
                  <th>Payment Mode</th>
                  <th>Order Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {(data || []).map((row, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{row.order_number}</td>
                    <td>{row.contactDetails.name}</td>
                    <td>{row.contactDetails.phone}</td>
                    <td>{row.payment_mode}</td>
                    <td>₹{formatNumberWithCommas(row.total)}</td>
                    <td>{row.status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </SimpleBar>
      </div>
    </Modal>
  )
}

export default businessAmount
