export const GET_PRODUCTS_LIST = "GET_PRODUCTS_LIST"
export const GET_PRODUCTS_LIST_SUCCESS = "GET_PRODUCTS_LIST_SUCCESS"
export const GET_PRODUCTS_LIST_FAIL = "GET_PRODUCTS_LIST_FAIL"

export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL"
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS"
export const GET_PRODUCT_DETAIL_FAIL = "GET_PRODUCT_DETAIL_FAIL"

export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT"
export const ADD_NEW_PRODUCT_SUCCESS = "ADD_NEW_PRODUCT_SUCCESS"
export const ADD_NEW_PRODUCT_FAIL = "ADD_NEW_PRODUCT_FAIL"

export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL"

export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL"

export const GET_CATEGORY_IN_PRODUCT = "GET_CATEGORY_IN_PRODUCT"
export const GET_CATEGORY_IN_PRODUCT_SUCCESS = "GET_CATEGORY_IN_PRODUCT_SUCCESS"
export const GET_CATEGORY_IN_PRODUCT_FAIL = "GET_CATEGORY_IN_PRODUCT_FAIL"

export const GET_SUBCATEGORY_IN_PRODUCT = "GET_SUBCATEGORY_IN_PRODUCT"
export const GET_SUBCATEGORY_IN_PRODUCT_SUCCESS =
  "GET_SUBCATEGORY_IN_PRODUCT_SUCCESS"
export const GET_SUBCATEGORY_IN_PRODUCT_FAIL = "GET_SUBCATEGORY_IN_PRODUCT_FAIL"

export const GET_SIZE_IN_PRODUCT = "GET_SIZE_IN_PRODUCT"
export const GET_SIZE_IN_PRODUCT_SUCCESS = "GET_SIZE_IN_PRODUCT_SUCCESS"
export const GET_SIZE_IN_PRODUCT_FAIL = "GET_SIZE_IN_PRODUCT_FAIL"

export const GET_SIZE_CHART_IN_PRODUCT = "GET_SIZE_CHART_IN_PRODUCT"
export const GET_SIZE_CHART_IN_PRODUCT_SUCCESS =
  "GET_SIZE_CHART_IN_PRODUCT_SUCCESS"
export const GET_SIZE_CHART_IN_PRODUCT_FAIL = "GET_SIZE_CHART_IN_PRODUCT_FAIL"

export const GET_COLOR_IN_VARIATION = "GET_COLOR_IN_VARIATION"
export const GET_COLOR_IN_VARIATION_SUCCESS = "GET_COLOR_IN_VARIATION_SUCCESS"
export const GET_COLOR_IN_VARIATION_FAIL = "GET_COLOR_IN_VARIATION_FAIL"

export const GET_VARIATION_LIST = "GET_VARIATION_LIST"
export const GET_VARIATION_LIST_SUCCESS = "GET_VARIATION_LIST_SUCCESS"
export const GET_VARIATION_LIST_FAIL = "GET_VARIATION_LIST_FAIL"

export const ADD_NEW_VARIATION = "ADD_NEW_VARIATION"
export const ADD_NEW_VARIATION_SUCCESS = "ADD_NEW_VARIATION_SUCCESS"
export const ADD_NEW_VARIATION_FAIL = "ADD_NEW_VARIATION_FAIL"

export const DELETE_VARIATION = "DELETE_VARIATION"
export const DELETE_VARIATION_SUCCESS = "DELETE_VARIATION_SUCCESS"
export const DELETE_VARIATION_FAIL = "DELETE_VARIATION_FAIL"

export const GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM =
  "GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM"
export const GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM_SUCCESS =
  "GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM_SUCCESS"
export const GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM_FAIL =
  "GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM_FAIL"
