import {
  ADD_NEW_PRODUCT,
  ADD_NEW_PRODUCT_SUCCESS,
  ADD_NEW_PRODUCT_FAIL,
  GET_PRODUCTS_LIST,
  GET_PRODUCTS_LIST_SUCCESS,
  GET_PRODUCTS_LIST_FAIL,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  GET_CATEGORY_IN_PRODUCT,
  GET_CATEGORY_IN_PRODUCT_SUCCESS,
  GET_CATEGORY_IN_PRODUCT_FAIL,
  GET_SUBCATEGORY_IN_PRODUCT,
  GET_SUBCATEGORY_IN_PRODUCT_SUCCESS,
  GET_SUBCATEGORY_IN_PRODUCT_FAIL,
  GET_SIZE_IN_PRODUCT,
  GET_SIZE_IN_PRODUCT_SUCCESS,
  GET_SIZE_IN_PRODUCT_FAIL,
  GET_SIZE_CHART_IN_PRODUCT,
  GET_SIZE_CHART_IN_PRODUCT_SUCCESS,
  GET_SIZE_CHART_IN_PRODUCT_FAIL,
  GET_COLOR_IN_VARIATION,
  GET_COLOR_IN_VARIATION_SUCCESS,
  GET_COLOR_IN_VARIATION_FAIL,
  GET_VARIATION_LIST,
  GET_VARIATION_LIST_SUCCESS,
  GET_VARIATION_LIST_FAIL,
  DELETE_VARIATION,
  DELETE_VARIATION_SUCCESS,
  DELETE_VARIATION_FAIL,
  ADD_NEW_VARIATION,
  ADD_NEW_VARIATION_SUCCESS,
  ADD_NEW_VARIATION_FAIL,
  GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM,
  GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM_SUCCESS,
  GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM_FAIL,
} from "./actionTypes"

export const getProductsList = () => ({
  type: GET_PRODUCTS_LIST,
})

export const getProductsListSuccess = products => ({
  type: GET_PRODUCTS_LIST_SUCCESS,
  payload: products,
})

export const getProductsListFail = error => ({
  type: GET_PRODUCTS_LIST_FAIL,
  payload: error,
})

export const getProductDetail = productId => ({
  type: GET_PRODUCT_DETAIL,
  productId,
})

export const getProductDetailSuccess = productDetails => ({
  type: GET_PRODUCT_DETAIL_SUCCESS,
  payload: productDetails,
})

export const getProductDetailFail = error => ({
  type: GET_PRODUCT_DETAIL_FAIL,
  payload: error,
})

export const addNewProduct = (product, history) => ({
  type: ADD_NEW_PRODUCT,
  payload: { product, history },
})

export const addNewProductSuccess = product => ({
  type: ADD_NEW_PRODUCT_SUCCESS,
  payload: product,
})

export const addNewProductFail = error => ({
  type: ADD_NEW_PRODUCT_FAIL,
  payload: error,
})

export const updateProduct = (id, product, history) => ({
  type: UPDATE_PRODUCT,
  payload: { id, product, history },
})

export const updateProductSuccess = product => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: product,
})

export const updateProductFail = error => ({
  type: UPDATE_PRODUCT_FAIL,
  payload: error,
})

export const deleteProduct = product => ({
  type: DELETE_PRODUCT,
  product,
})

export const deleteProductSuccess = product => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: product,
})

export const deleteProductFail = error => ({
  type: DELETE_PRODUCT_FAIL,
  payload: error,
})

export const getCategoryInProduct = () => ({
  type: GET_CATEGORY_IN_PRODUCT,
})

export const getCategoryInProductSuccess = categories => ({
  type: GET_CATEGORY_IN_PRODUCT_SUCCESS,
  payload: categories,
})

export const getCategoryInProductFail = error => ({
  type: GET_CATEGORY_IN_PRODUCT_FAIL,
  payload: error,
})

export const getSubcategoryInProduct = categoryId => ({
  type: GET_SUBCATEGORY_IN_PRODUCT,
  categoryId,
})

export const getSubcategoryInProductSuccess = subcategories => ({
  type: GET_SUBCATEGORY_IN_PRODUCT_SUCCESS,
  payload: subcategories,
})

export const getSubcategoryInProductFail = error => ({
  type: GET_SUBCATEGORY_IN_PRODUCT_FAIL,
  payload: error,
})

export const getSizeInProduct = () => ({
  type: GET_SIZE_IN_PRODUCT,
})

export const getSizeInProductSuccess = sizes => ({
  type: GET_SIZE_IN_PRODUCT_SUCCESS,
  payload: sizes,
})

export const getSizeInProductFail = error => ({
  type: GET_SIZE_IN_PRODUCT_FAIL,
  payload: error,
})

export const getSizeChartInProduct = () => ({
  type: GET_SIZE_CHART_IN_PRODUCT,
})

export const getSizeChartInProductSuccess = sizeCharts => ({
  type: GET_SIZE_CHART_IN_PRODUCT_SUCCESS,
  payload: sizeCharts,
})

export const getSizeChartInProductFail = error => ({
  type: GET_SIZE_CHART_IN_PRODUCT_FAIL,
  payload: error,
})

export const getColorInVariation = () => ({
  type: GET_COLOR_IN_VARIATION,
})

export const getColorInVariationSuccess = color => ({
  type: GET_COLOR_IN_VARIATION_SUCCESS,
  payload: color,
})

export const getColorInVariationFail = error => ({
  type: GET_COLOR_IN_VARIATION_FAIL,
  payload: error,
})

export const getVariationList = productId => ({
  type: GET_VARIATION_LIST,
  productId,
})

export const getVariationListSuccess = variations => ({
  type: GET_VARIATION_LIST_SUCCESS,
  payload: variations,
})

export const getVariationListFail = error => ({
  type: GET_VARIATION_LIST_FAIL,
  payload: error,
})

export const deleteVariation = (id, variation) => ({
  type: DELETE_VARIATION,
  payload: { id, variation },
})

export const deleteVariationSuccess = variation => ({
  type: DELETE_VARIATION_SUCCESS,
  payload: variation,
})

export const deleteVariationFail = error => ({
  type: DELETE_VARIATION_FAIL,
  payload: error,
})

export const addNewVariation = (id, product, history) => ({
  type: ADD_NEW_VARIATION,
  payload: { id, product, history },
})

export const addNewVariationSuccess = product => ({
  type: ADD_NEW_VARIATION_SUCCESS,
  payload: product,
})

export const addNewVariationFail = error => ({
  type: ADD_NEW_VARIATION_FAIL,
  payload: error,
})

export const getSizesForSizeChartByTopOrBottom = data => ({
  type: GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM,
  data,
})

export const getSizesForSizeChartByTopOrBottomSuccess = sizes => ({
  type: GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM_SUCCESS,
  payload: sizes,
})

export const getSizesForSizeChartByTopOrBottomFail = error => ({
  type: GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM_FAIL,
  payload: error,
})
