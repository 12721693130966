import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"

import { useDispatch } from "react-redux"
import { addTrackingDetail, getAllOrdersList } from "store/actions"
import { useSelector } from "react-redux"
import { API_URL } from "helpers/url_helper"
import TrackOrderDetailModal from "./trackOrderDetailModal"
import Swal from "sweetalert2"
import SimpleBar from "simplebar-react"
import jsPDF from "jspdf"
import "jspdf-autotable"
//Import Breadcrumb

const OrderDetail = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let { id } = useParams()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const userName = user?.data.user_name

  useEffect(() => {
    dispatch(getAllOrdersList(id))
  }, [dispatch])

  const dataState = state => state.orders.ordersList.data
  const data = useSelector(dataState) || []
  const order = data.length > 0 ? data[0] : {}

  const [modalLarge, setModalLarge] = useState(false)
  const toggleModalLarge = () => setModalLarge(!modalLarge)

  const customizationTitles = {
    customizeType: "Type",
    fontStyle: "Font Style",
    fontColor: "Font Color",
    logoUrl: "Logo",
    text: "Text",
  }

  document.title = "Order Detail"
  const formatDate = dateString => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }
    const formattedDate = new Date(dateString).toLocaleString("en-US", options)
    return formattedDate
  }

  const handleActionChange = event => {
    const selectedAction = event.target.value
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to change the status to ${selectedAction}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, do it!",
      cancelButtonText: "No, cancel!",
    }).then(result => {
      if (result.isConfirmed) {
        if (selectedAction === "DISPATCHED") {
          toggleModalLarge()
        } else {
          const value = {
            orderId: id,
            createdBy: userName,
            status: selectedAction,
          }
          dispatch(addTrackingDetail(value))
        }
      } else {
        console.log("Action cancelled")
      }
      event.target.value = "Action"
    })
  }

  const handleViewLogo = logoUrl => {
    // Logic to view the logo, e.g., open in a new tab
    window.open(`${API_URL}/public${logoUrl?.replace(/\[|\]/g, "")}`, "_blank")
  }

  const handleDownloadOrderSlip = () => {
    const doc = new jsPDF()

    doc.setFontSize(18)
    doc.text("Order Slip", 14, 22)
    doc.setFontSize(12)
    doc.text(`Order Number: ${order.order_number}`, 14, 32)

    const tableColumn = [
      "Product Name",
      "Quantity",
      "Color",
      "Size",
      "Customization",
    ]
    const tableRows = []

    order.items.forEach(item => {
      const itemData = [
        item.product_name,
        item.quantity,
        item.color_name,
        item.multiSize
          ? `Top Size: ${item.size_top[0]?.size_title}, Bottom Size: ${item.size_bottom[0]?.size_title}`
          : `Size: ${item.size[0]?.size_title}`,
        item.customization && item.customizations
          ? item.customizations.customizeType
            ? `Type: ${item.customizations.customizeType}`
            : "None"
          : "None",
      ]
      tableRows.push(itemData)
    })

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 40,
    })

    doc.save(`order_${order.order_number}.pdf`)
  }
  return (
    <React.Fragment>
      <TrackOrderDetailModal
        isOpen={modalLarge}
        toggle={toggleModalLarge}
        orderId={id}
      />
      <div className="page-content">
        <Container fluid>
          <CardTitle className="mb-2 font-size-18">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i
                  className="mdi mdi-arrow-left font-size-18 me-2"
                  onClick={() => {
                    navigate(-1)
                  }}
                ></i>
                Order Detail
              </div>

              {order.status !== "CANCELLED" && (
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-primary me-2 w-100"
                    onClick={handleDownloadOrderSlip}
                  >
                    <i className="mdi mdi-download me-1"></i>
                    Order Slip
                  </button>
                  <select
                    className="form-select me-2"
                    name="action"
                    onChange={handleActionChange}
                    value={order.status}
                  >
                    <option disabled selected>
                      Action
                    </option>
                    <option>ORDER PLACED</option>
                    <option>ACCEPTED</option>
                    <option>IN PACKAGING</option>
                    <option>DISPATCHED</option>
                    <option>DELIVERY FAILED</option>
                    <option>DELAYED</option>
                    <option>DELIVERED</option>
                  </select>
                </div>
              )}
            </div>
          </CardTitle>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={6}>
                      <h5 style={{ marginBottom: "1rem" }}>
                        Order Number: {order.order_number}
                      </h5>
                      <p style={{ marginBottom: "0.5rem" }}>
                        Order Date: {formatDate(order.createdAt)}
                      </p>
                      <p style={{ marginBottom: "0.5rem" }}>
                        Payment Mode: {order.payment_mode}
                      </p>
                      <p style={{ marginBottom: "0.5rem" }}>
                        Status: {order.status}{" "}
                        {order.status === "CANCELLED" &&
                          `(${order.cancelReason})`}
                      </p>
                      <p style={{ marginBottom: "2rem" }}>
                        Total: ₹{order.total}
                      </p>
                    </Col>
                    <Col lg={6}>
                      <div style={{ marginBottom: "2rem" }}>
                        <h5 style={{ marginBottom: "1rem" }}>
                          Shipping Address
                        </h5>
                        <p style={{ marginBottom: "0.5rem" }}>
                          {order.shippingAddress?.address_line_1}
                        </p>
                        <p style={{ marginBottom: "0.5rem" }}>
                          {order.shippingAddress?.address_line_2}
                        </p>
                        <p style={{ marginBottom: "0.5rem" }}>
                          {order.shippingAddress?.address_line_3}
                        </p>
                        <p style={{ marginBottom: "0.5rem" }}>
                          Pin Code: {order.shippingAddress?.pin_code}
                        </p>
                        <p style={{ marginBottom: "0.5rem" }}>
                          State: {order.shippingAddress?.state}
                        </p>
                        <p style={{ marginBottom: "0.5rem" }}>
                          Country: {order.shippingAddress?.country}
                        </p>
                        {order.shippingAddress?.companyDetail === true && (
                          <>
                            <p style={{ marginBottom: "0.5rem" }}>
                              Company Name: {order.shippingAddress?.companyName}
                            </p>
                            <p style={{ marginBottom: "0.5rem" }}>
                              GST Number: {order.shippingAddress?.gstNo}
                            </p>{" "}
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div style={{ marginBottom: "2rem" }}>
                        <h5 style={{ marginBottom: "1rem" }}>
                          Billing Address
                        </h5>
                        <p style={{ marginBottom: "0.5rem" }}>
                          {order.billingAddress?.address_line_1}
                        </p>
                        <p style={{ marginBottom: "0.5rem" }}>
                          {order.billingAddress?.address_line_2}
                        </p>
                        <p style={{ marginBottom: "0.5rem" }}>
                          {order.billingAddress?.address_line_3}
                        </p>
                        <p style={{ marginBottom: "0.5rem" }}>
                          Pin Code: {order.billingAddress?.pin_code}
                        </p>
                        <p style={{ marginBottom: "0.5rem" }}>
                          State: {order.billingAddress?.state}
                        </p>
                        <p style={{ marginBottom: "0.5rem" }}>
                          Country: {order.billingAddress?.country}
                        </p>
                        {order.billingAddress?.companyDetail === true && (
                          <>
                            <p style={{ marginBottom: "0.5rem" }}>
                              Company Name: {order.billingAddress?.companyName}
                            </p>
                            <p style={{ marginBottom: "0.5rem" }}>
                              GST Number: {order.billingAddress?.gstNo}
                            </p>{" "}
                          </>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div style={{ marginBottom: "2rem" }}>
                        <h5 style={{ marginBottom: "1rem" }}>
                          Contact Details
                        </h5>
                        <p style={{ marginBottom: "0.5rem" }}>
                          Name: {order.contactDetails?.name}
                        </p>
                        <p style={{ marginBottom: "0.5rem" }}>
                          Email: {order.contactDetails?.email}
                        </p>
                        <p style={{ marginBottom: "0.5rem" }}>
                          Phone: {order.contactDetails?.phone}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  {order.trackDetail?.deliveryPartner &&
                    order.trackDetail?.trackingId &&
                    order.trackDetail?.trackingSite && (
                      <Row>
                        <Col>
                          <div style={{ marginBottom: "2rem" }}>
                            <h5 style={{ marginBottom: "1rem" }}>
                              Tracking Details
                            </h5>
                            <p style={{ marginBottom: "0.5rem" }}>
                              Delivery Partner:{" "}
                              {order.trackDetail.deliveryPartner}
                            </p>
                            <p style={{ marginBottom: "0.5rem" }}>
                              Tracking Id: {order.trackDetail.trackingId}
                            </p>
                            <p style={{ marginBottom: "0.5rem" }}>
                              Tracking Site: {order.trackDetail.trackingSite}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    )}
                  <h5 style={{ marginBottom: "1rem" }}>Order Items</h5>
                  {order.items &&
                    order.items.map((item, index) => (
                      <Card
                        key={index}
                        className="mb-3"
                        style={{ marginBottom: "1rem" }}
                      >
                        <CardBody
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div style={{ flex: "1" }}>
                            <h6 style={{ marginBottom: "1rem" }}>
                              Product Name: {item.product_name}
                            </h6>
                            <p style={{ marginBottom: "0.5rem" }}>
                              {item.sku_code && `SKU Code: ${item.sku_code}`}
                            </p>
                            <p style={{ marginBottom: "0.5rem" }}>
                              Quantity: {item.quantity}
                            </p>
                            <p style={{ marginBottom: "0.5rem" }}>
                              Color: {item.color_name}
                            </p>
                            <p style={{ marginBottom: "0.5rem" }}>
                              {item.multiSize === true ? (
                                <span>
                                  Top Size: {item.size_top[0]?.size_title},
                                  Bottom Size: {item.size_bottom[0]?.size_title}
                                </span>
                              ) : (
                                <span>Size: {item.size[0]?.size_title}</span>
                              )}
                            </p>
                            <p style={{ marginBottom: "0.5rem" }}>
                              Total: ₹{item.subtotal}
                            </p>

                            {item.customization &&
                              (item.customizations.fontStyle ||
                                item.customizations.fontColor ||
                                item.customizations.logoUrl ||
                                item.customizations.text) && (
                                <div>
                                  <h6>Customizations:</h6>
                                  <ul>
                                    {Object.entries(item.customizations).map(
                                      ([key, value]) =>
                                        key === "logoUrl" && value ? (
                                          <li key={key}>
                                            <strong>
                                              {customizationTitles[key] || key}:{" "}
                                            </strong>
                                            <button
                                              onClick={() =>
                                                handleViewLogo(value)
                                              }
                                            >
                                              <img
                                                src={`${API_URL}/public${value?.replace(
                                                  /\[|\]/g,
                                                  ""
                                                )}`}
                                                alt="Logo"
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                  marginBottom: "5px",
                                                }}
                                              />
                                            </button>
                                          </li>
                                        ) : key !== "logoUrl" ? (
                                          <li key={key}>
                                            <strong>
                                              {customizationTitles[key] || key}:{" "}
                                            </strong>{" "}
                                            {value}
                                          </li>
                                        ) : null
                                    )}
                                  </ul>
                                </div>
                              )}
                          </div>
                          <div
                            style={{
                              flex: "1",
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            <img
                              src={`${API_URL}/public${item.variation?.images[0]}`}
                              alt="Product"
                              style={{
                                maxWidth: "100px",
                                marginRight: "10px",
                                marginBottom: "10px",
                              }}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    ))}
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex align-items-start">
                    <div className="me-2">
                      <h5 className="card-title mb-4">
                        Track Order ({order.order_number})
                      </h5>
                    </div>
                  </div>
                  <SimpleBar className="mt-2" style={{ maxHeight: "280px" }}>
                    {order &&
                      order?.logs?.map((track, index) => (
                        <ul
                          key={index}
                          className="verti-timeline list-unstyled"
                        >
                          <li
                            className={`event-list ${track.to ? "active" : ""}`}
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="event-timeline-dot">
                              <i
                                className={`bx bxs-right-arrow-circle font-size-18 ${
                                  track.from && "bx-fade-right"
                                }`}
                              ></i>
                            </div>
                            <div className="d-flex">
                              <div className="flex-shrink-0 me-3">
                                <h5 className="font-size-14">
                                  {track.from}
                                  <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                </h5>
                              </div>
                              <div className={`flex-grow-1 ${track.from}`}>
                                {track.to} ({formatDate(track.createdAt)}){" "}
                                {track.createdBy && `- (${track.createdBy})`}
                              </div>
                            </div>
                          </li>
                        </ul>
                      ))}
                  </SimpleBar>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(OrderDetail)

OrderDetail.propTypes = {
  history: PropTypes.object,
}
