import {
  GET_NEWSLETTER_LIST_FAIL,
  GET_NEWSLETTER_LIST_SUCCESS,
} from "./actionTypes"

const initialState = {
  newsLetterList: [],
  error: {},
  loading: false,
}

const newsLetter = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEWSLETTER_LIST_SUCCESS:
      return {
        ...state,
        newsLetterList: action.payload,
        loading: false,
      }

    case GET_NEWSLETTER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default newsLetter
