import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  ADD_NEW_COLOR,
  DELETE_COLOR,
  GET_COLORS_LIST,
  GET_COLOR_DETAIL,
  UPDATE_COLOR,
} from "./actionTypes"
import {
  addNewColorFail,
  addNewColorSuccess,
  deleteColorFail,
  deleteColorSuccess,
  getColorDetailFail,
  getColorDetailSuccess,
  getColorsListFail,
  getColorsListSuccess,
  updateColorFail,
  updateColorSuccess,
} from "./actions"
import {
  addNewColor,
  deleteColor,
  getColorDetails,
  getColorsList,
  updateColor,
} from "helpers/fakebackend_helper"
import { toast } from "react-toastify"
import Swal from "sweetalert2"

//Include Both Helper File with needed methods

function* fetchColors() {
  try {
    const response = yield call(getColorsList)
    yield put(getColorsListSuccess(response))
  } catch (error) {
    yield put(getColorsListFail(error))
  }
}

function* fetchColorDetail({ colorId }) {
  try {
    const response = yield call(getColorDetails, colorId)
    yield put(getColorDetailSuccess(response))
  } catch (error) {
    yield put(getColorDetailFail(error))
  }
}

function* onAddNewColor({ payload: { color, history } }) {
  try {
    const response = yield call(addNewColor, color)
    yield put(addNewColorSuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Color has been added successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history("/list-colors")
        }
      })
    }
  } catch (error) {
    yield put(addNewColorFail(error))
    toast.error(error?.response?.data?.msg, { autoClose: 2000 })
  }
}

function* onUpdateColor({ payload: { id, color, history } }) {
  try {
    const response = yield call(updateColor, id, color)
    yield put(updateColorSuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Color has been updated successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history("/list-colors")
        }
      })
    }
  } catch (error) {
    yield put(updateColorFail(error))
    toast.error("Color Update Failed", { autoClose: 2000 })
  }
}

function* onDeleteColor({ color }) {
  try {
    const response = yield call(deleteColor, color)
    yield put(deleteColorSuccess(response))
    const response2 = yield call(getColorsList)
    yield put(getColorsListSuccess(response2))
    toast.success("Color Deleted Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteColorFail(error))
    toast.error("Color Delete Failed", { autoClose: 2000 })
  }
}

function* colorSaga() {
  yield takeEvery(GET_COLORS_LIST, fetchColors)
  yield takeEvery(GET_COLOR_DETAIL, fetchColorDetail)
  yield takeEvery(ADD_NEW_COLOR, onAddNewColor)
  yield takeEvery(UPDATE_COLOR, onUpdateColor)
  yield takeEvery(DELETE_COLOR, onDeleteColor)
}

export default colorSaga
