import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  ADD_NEW_SIZE,
  DELETE_SIZE,
  GET_SIZES_LIST,
  GET_SIZE_DETAIL,
  UPDATE_SIZE,
} from "./actionTypes"
import {
  addNewSizeFail,
  addNewSizeSuccess,
  deleteSizeFail,
  deleteSizeSuccess,
  getSizeDetailFail,
  getSizeDetailSuccess,
  getSizesListFail,
  getSizesListSuccess,
  updateSizeFail,
  updateSizeSuccess,
} from "./actions"
import {
  addNewSize,
  deleteSize,
  getSizeDetails,
  getSizesList,
  updateSize,
} from "helpers/fakebackend_helper"
import { toast } from "react-toastify"
import Swal from "sweetalert2"

//Include Both Helper File with needed methods

function* fetchSizes() {
  try {
    const response = yield call(getSizesList)
    yield put(getSizesListSuccess(response))
  } catch (error) {
    yield put(getSizesListFail(error))
  }
}

function* fetchSizeDetail({ sizeId }) {
  try {
    const response = yield call(getSizeDetails, sizeId)
    yield put(getSizeDetailSuccess(response))
  } catch (error) {
    yield put(getSizeDetailFail(error))
  }
}

function* onAddNewSize({ payload: { size, history } }) {
  try {
    const response = yield call(addNewSize, size)
    yield put(addNewSizeSuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Size has been added successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history("/list-sizes")
        }
      })
    }
  } catch (error) {
    yield put(addNewSizeFail(error))
    toast.error(error?.response?.data?.msg, { autoClose: 2000 })
  }
}

function* onUpdateSize({ payload: { id, size, history } }) {
  try {
    const response = yield call(updateSize, id, size)
    yield put(updateSizeSuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Size has been updated successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history("/list-sizes")
        }
      })
    }
  } catch (error) {
    yield put(updateSizeFail(error))
    toast.error("Size Update Failed", { autoClose: 2000 })
  }
}

function* onDeleteSize({ size }) {
  try {
    const response = yield call(deleteSize, size)
    yield put(deleteSizeSuccess(response))
    const response2 = yield call(getSizesList)
    yield put(getSizesListSuccess(response2))
    toast.success("Size Deleted Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteSizeFail(error))
    toast.error("Size Delete Failed", { autoClose: 2000 })
  }
}

function* sizeSaga() {
  yield takeEvery(GET_SIZES_LIST, fetchSizes)
  yield takeEvery(GET_SIZE_DETAIL, fetchSizeDetail)
  yield takeEvery(ADD_NEW_SIZE, onAddNewSize)
  yield takeEvery(UPDATE_SIZE, onUpdateSize)
  yield takeEvery(DELETE_SIZE, onDeleteSize)
}

export default sizeSaga
