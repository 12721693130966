import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  ADD_NEW_CATEGORY,
  DELETE_CATEGORY,
  GET_CATEGORIES_LIST,
  GET_CATEGORY_DETAIL,
  UPDATE_CATEGORY,
} from "./actionTypes"
import {
  addNewCategoryFail,
  addNewCategorySuccess,
  deleteCategoryFail,
  deleteCategorySuccess,
  getCategoryDetailFail,
  getCategoryDetailSuccess,
  getCategoriesListFail,
  getCategoriesListSuccess,
  updateCategoryFail,
  updateCategorySuccess,
} from "./actions"
import {
  addNewCategory,
  deleteCategory,
  getCategoryDetails,
  getCategoriesList,
  updateCategory,
} from "helpers/fakebackend_helper"
import { toast } from "react-toastify"
import Swal from "sweetalert2"

//Include Both Helper File with needed methods

function* fetchCategories() {
  try {
    const response = yield call(getCategoriesList)
    yield put(getCategoriesListSuccess(response))
  } catch (error) {
    yield put(getCategoriesListFail(error))
  }
}

function* fetchCategoryDetail({ categoryId }) {
  try {
    const response = yield call(getCategoryDetails, categoryId)
    yield put(getCategoryDetailSuccess(response))
  } catch (error) {
    yield put(getCategoryDetailFail(error))
  }
}

function* onAddNewCategory({ payload: { category, history } }) {
  try {
    const response = yield call(addNewCategory, category)
    yield put(addNewCategorySuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Category has been added successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history("/list-categories")
        }
      })
    }
  } catch (error) {
    yield put(addNewCategoryFail(error))
    toast.error(error?.response?.data?.msg, { autoClose: 2000 })
  }
}

function* onUpdateCategory({ payload: { id, category, history } }) {
  try {
    const response = yield call(updateCategory, id, category)
    yield put(updateCategorySuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Category has been updated successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history("/list-categories")
        }
      })
    }
  } catch (error) {
    yield put(updateCategoryFail(error))
    toast.error("Category Update Failed", { autoClose: 2000 })
  }
}

function* onDeleteCategory({ category }) {
  try {
    const response = yield call(deleteCategory, category)
    yield put(deleteCategorySuccess(response))
    const response2 = yield call(getCategoriesList)
    yield put(getCategoriesListSuccess(response2))
    toast.success("Category Deleted Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteCategoryFail(error))
    toast.error("Category Delete Failed", { autoClose: 2000 })
  }
}

function* categorySaga() {
  yield takeEvery(GET_CATEGORIES_LIST, fetchCategories)
  yield takeEvery(GET_CATEGORY_DETAIL, fetchCategoryDetail)
  yield takeEvery(ADD_NEW_CATEGORY, onAddNewCategory)
  yield takeEvery(UPDATE_CATEGORY, onUpdateCategory)
  yield takeEvery(DELETE_CATEGORY, onDeleteCategory)
}

export default categorySaga
