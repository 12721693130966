import {
  GET_CONTACT_US_LIST_SUCCESS,
  GET_CONTACT_US_LIST_FAIL,
} from "./actionTypes"

const initialState = {
  contactList: [],
  error: {},
  loading: false,
}

const contactUs = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACT_US_LIST_SUCCESS:
      return {
        ...state,
        contactList: action.payload,
        loading: false,
      }

    case GET_CONTACT_US_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default contactUs
