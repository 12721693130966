import {
  GET_ALL_ORDERS,
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_ORDERS_FAIL,
  ADD_TRACKING_DETAIL,
  ADD_TRACKING_DETAIL_SUCCESS,
  ADD_TRACKING_DETAIL_FAIL,
  GET_DELIVERED_ORDERS,
  GET_DELIVERED_ORDERS_SUCCESS,
  GET_DELIVERED_ORDERS_FAIL,
} from "./actionTypes"

export const getAllOrdersList = id => ({
  type: GET_ALL_ORDERS,
  id,
})

export const getAllOrdersListSuccess = users => ({
  type: GET_ALL_ORDERS_SUCCESS,
  payload: users,
})

export const getAllOrdersListFail = error => ({
  type: GET_ALL_ORDERS_FAIL,
  payload: error,
})

export const addTrackingDetail = (data, toggle, history) => ({
  type: ADD_TRACKING_DETAIL,
  payload: { data, toggle, history },
})

export const addTrackingDetailSuccess = data => ({
  type: ADD_TRACKING_DETAIL_SUCCESS,
  payload: data,
})

export const addTrackingDetailFail = error => ({
  type: ADD_TRACKING_DETAIL_FAIL,
  payload: error,
})

export const getDeliveredOrdersList = () => ({
  type: GET_DELIVERED_ORDERS,
})

export const getDeliveredOrdersListSuccess = orders => ({
  type: GET_DELIVERED_ORDERS_SUCCESS,
  payload: orders,
})

export const getDeliveredOrdersListFail = error => ({
  type: GET_DELIVERED_ORDERS_FAIL,
  payload: error,
})
