import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { getSizeDetail, updateSize } from "store/actions"
import { useSelector } from "react-redux"

const EditSize = props => {
  //meta title
  document.title = "Edit Size"
  const dispatch = useDispatch()
  let { id } = useParams()

  useEffect(() => {
    dispatch(getSizeDetail(id))
  }, [dispatch])

  const dataState = state => state.sizes.sizeDetail.data
  const data = useSelector(dataState) || []
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      size_title: (data && data.size_title) || "",
      is_active: (data && data.is_active) || false,
    },

    validationSchema: Yup.object({
      size_title: Yup.string().required("Please Enter Size Title"),
      is_active: Yup.boolean(),
    }),

    onSubmit: async values => {
      dispatch(updateSize(id, values, props.router.navigate))
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CardTitle className="mb-2 font-size-18">
            <Link to="/list-sizes" style={{ color: "black" }}>
              <i className="mdi mdi-arrow-left font-size-18 me-2"></i>
            </Link>
            Edit Size
          </CardTitle>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Size Title*</Label>
                          <Input
                            id="size_title"
                            name="size_title"
                            type="text"
                            className="form-control"
                            placeholder="Enter Size Title"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.size_title &&
                              validation.errors.size_title
                                ? true
                                : false
                            }
                            value={validation.values.size_title}
                          />
                          {validation.touched.size_title &&
                          validation.errors.size_title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.size_title}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <Label htmlFor="is_active">Is Active</Label>
                        </div>
                        <Col lg="12">
                          <div className="form-check form-check-inline">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="is_active"
                              name="is_active"
                              onBlur={validation.handleBlur}
                              onClick={e => {
                                validation.setFieldValue(
                                  "is_active",
                                  !e.target.checked
                                )
                              }}
                              checked={Boolean(validation?.values?.is_active)}
                            />
                            <Label
                              className="form-check-Label"
                              htmlFor="is_active"
                            >
                              Yes
                            </Label>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                    <Row className="align-items-center mt-3">
                      <Col className="text-end">
                        <button
                          className="w-md "
                          type="submit"
                          style={{
                            backgroundColor: "#082a6b",
                            color: "#ffffff",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            height: "35px",
                          }}
                        >
                          Edit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(EditSize)
// export default EditSize

EditSize.propTypes = {
  history: PropTypes.object,
}
