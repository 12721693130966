import {
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAIL,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
} from "./actionTypes"

const initialState = {
  userList: [],
  users: [],
  userDetail: {},
  error: {},
  loading: false,
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        userList: action.payload,
        loading: false,
      }

    case GET_USERS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
        loading: false,
      }

    case GET_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NEW_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
        loading: false,
      }

    case ADD_NEW_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: {
            ...state.userDetail.data,
            [action.payload.data._id]: {
              ...state.userDetail.data[action.payload.data._id],
              ...action.payload.data,
            },
          },
        },
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        userList: state.userList.data.filter(
          user => user._id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default users
