import {
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_ORDERS_FAIL,
  ADD_TRACKING_DETAIL_SUCCESS,
  ADD_TRACKING_DETAIL_FAIL,
  GET_DELIVERED_ORDERS_SUCCESS,
  GET_DELIVERED_ORDERS_FAIL,
} from "./actionTypes"

const initialState = {
  ordersList: [],
  deliveredOrdersList: [],
  trackingDetails: [],
  error: {},
  loading: false,
}

const orders = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        ordersList: action.payload,
        loading: false,
      }

    case GET_ALL_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_TRACKING_DETAIL_SUCCESS:
      return {
        ...state,
        trackingDetails: [...state.trackingDetails, action.payload],
        loading: false,
      }

    case ADD_TRACKING_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_DELIVERED_ORDERS_SUCCESS:
      return {
        ...state,
        deliveredOrdersList: action.payload,
        loading: false,
      }

    case GET_DELIVERED_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default orders
