import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  CardTitle,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, socialLogin } from "../../store/actions"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// import images
import logo from "assets/Medilinen/FullLogo.jpg"
import Glogo from "assets/Medilinen/Glogo.jpg"
import Flogo from "assets/Medilinen/Flogo.jpg"

const Login = props => {
  const [passwordShow, setPasswordShow] = useState(false)

  //meta title
  document.title = "Login"

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.router.navigate))
    },
  })
  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    error: login.error,
  }))

  const { error } = useSelector(LoginProperties)

  const signIn = type => {
    dispatch(socialLogin(type, props.router.navigate))
  }

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type)
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div>
                  <Row>
                    <Col xs={5} className="align-self-end">
                      <img src={logo} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <CardTitle className="mb-5" style={{ fontSize: "20px" }}>
                      Login Your Account
                    </CardTitle>

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <div className="mb-3">
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter Your Email Address"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Row>
                      <Row>
                        <div className="mb-3">
                          <div className="input-group auth-pass-inputgroup">
                            <div className="col">
                              <Input
                                type={passwordShow ? "text" : "password"}
                                id="password"
                                placeholder="Enter Password"
                                name="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                className={`form-control ${
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div>
                              <button
                                onClick={() => setPasswordShow(!passwordShow)}
                                className="btn btn-light"
                                type="button"
                                id="password-addon"
                              >
                                <i className="mdi mdi-eye-outline"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </Row>

                      <Row className="align-items-center">
                        {/* <Col>
                          <Link to="/forgot-password">
                            <Label style={{ color: "#082A6B" }}>
                              Forgot Password?
                            </Label>
                          </Link>
                        </Col> */}

                        <Col className="text-end">
                          <button
                            className="w-md "
                            type="submit"
                            style={{
                              backgroundColor: "#082a6b",
                              color: "#ffffff",
                              borderTopRightRadius: "10px",
                              borderBottomLeftRadius: "10px",
                              height: "35px",
                            }}
                          >
                            Login
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
