import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import UserSaga from "./user/saga"
import categorySaga from "./category/saga"
import colorSaga from "./color/saga"
import sizeSaga from "./size/saga"
import productSaga from "./product/saga"
import subcategorySaga from "./subcategory/saga"
import sizeChartSaga from "./sizechart/saga"
import newsLetterSaga from "./newsletter/saga"
import ecomusersSaga from "./ecomusers/saga"
import ordersSaga from "./orders/saga"
import contactUsSaga from "./contactUs/saga"
import dashboardSaga from "./dashboard/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(UserSaga),
    fork(categorySaga),
    fork(subcategorySaga),
    fork(colorSaga),
    fork(sizeSaga),
    fork(sizeChartSaga),
    fork(productSaga),
    fork(newsLetterSaga),
    fork(ecomusersSaga),
    fork(ordersSaga),
    fork(contactUsSaga),
    fork(dashboardSaga),
  ])
}
