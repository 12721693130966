export const GET_COLORS_LIST = "GET_COLORS_LIST"
export const GET_COLORS_LIST_SUCCESS = "GET_COLORS_LIST_SUCCESS"
export const GET_COLORS_LIST_FAIL = "GET_COLORS_LIST_FAIL"

export const GET_COLOR_DETAIL = "GET_COLOR_DETAIL"
export const GET_COLOR_DETAIL_SUCCESS = "GET_COLOR_DETAIL_SUCCESS"
export const GET_COLOR_DETAIL_FAIL = "GET_COLOR_DETAIL_FAIL"

export const ADD_NEW_COLOR = "ADD_NEW_COLOR"
export const ADD_NEW_COLOR_SUCCESS = "ADD_NEW_COLOR_SUCCESS"
export const ADD_NEW_COLOR_FAIL = "ADD_NEW_COLOR_FAIL"

export const UPDATE_COLOR = "UPDATE_COLOR"
export const UPDATE_COLOR_SUCCESS = "UPDATE_COLOR_SUCCESS"
export const UPDATE_COLOR_FAIL = "UPDATE_COLOR_FAIL"

export const DELETE_COLOR = "DELETE_COLOR"
export const DELETE_COLOR_SUCCESS = "DELETE_COLOR_SUCCESS"
export const DELETE_COLOR_FAIL = "DELETE_COLOR_FAIL"
