import {
  ADD_NEW_SUBCATEGORY_SUCCESS,
  ADD_NEW_SUBCATEGORY_FAIL,
  GET_SUBCATEGORIES_LIST_SUCCESS,
  GET_SUBCATEGORIES_LIST_FAIL,
  GET_SUBCATEGORY_DETAIL_SUCCESS,
  GET_SUBCATEGORY_DETAIL_FAIL,
  UPDATE_SUBCATEGORY_SUCCESS,
  UPDATE_SUBCATEGORY_FAIL,
  DELETE_SUBCATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_FAIL,
  GET_CATEGORIES_LIST_FOR_SUBCATEGORY_SUCCESS,
  GET_CATEGORIES_LIST_FOR_SUBCATEGORY_FAIL,
} from "./actionTypes"

const initialState = {
  subcategoryList: [],
  subcategories: [],
  categoryList: [],
  subcategoryDetail: {},
  error: {},
  loading: false,
}

const subcategories = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBCATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        subcategoryList: action.payload,
        loading: false,
      }

    case GET_SUBCATEGORIES_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SUBCATEGORY_DETAIL_SUCCESS:
      return {
        ...state,
        subcategoryDetail: action.payload,
        loading: false,
      }

    case GET_SUBCATEGORY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NEW_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subcategories: [...state.subcategories, action.payload],
        loading: false,
      }

    case ADD_NEW_SUBCATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subcategoryDetail: {
          ...state.subcategoryDetail,
          data: {
            ...state.subcategoryDetail.data,
            [action.payload.data._id]: {
              ...state.subcategoryDetail.data[action.payload.data._id],
              ...action.payload.data,
            },
          },
        },
      }

    case UPDATE_SUBCATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subcategoryList: state.subcategoryList.data.filter(
          category => category._id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_SUBCATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case GET_CATEGORIES_LIST_FOR_SUBCATEGORY_SUCCESS:
        return {
          ...state,
          categoryList: action.payload,
          loading: false,
        }
  
      case GET_CATEGORIES_LIST_FOR_SUBCATEGORY_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  

    default:
      state = { ...state }
      break
  }
  return state
}

export default subcategories
