import {
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
} from "./actionTypes"

const initialState = {
  dashboardData: {},
  error: {},
  loading: false,
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload,
        loading: false,
      }

    case GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default dashboard
