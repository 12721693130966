import {
  ADD_NEW_USER,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAIL,
  GET_USERS_LIST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_DETAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAIL,
} from "./actionTypes"

export const getUsersList = () => ({
  type: GET_USERS_LIST,
})

export const getUsersListSuccess = users => ({
  type: GET_USERS_LIST_SUCCESS,
  payload: users,
})

export const getUsersListFail = error => ({
  type: GET_USERS_LIST_FAIL,
  payload: error,
})

export const getUserDetail = userId => ({
  type: GET_USER_DETAIL,
  userId,
})

export const getUserDetailSuccess = userDetails => ({
  type: GET_USER_DETAIL_SUCCESS,
  payload: userDetails,
})

export const getUserDetailFail = error => ({
  type: GET_USER_DETAIL_FAIL,
  payload: error,
})

export const addNewUser = (user, history) => ({
  type: ADD_NEW_USER,
  payload: { user, history },
})

export const addNewUserSuccess = user => ({
  type: ADD_NEW_USER_SUCCESS,
  payload: user,
})

export const addNewUserFail = error => ({
  type: ADD_NEW_USER_FAIL,
  payload: error,
})

export const updateUser = (id, user, history) => ({
  type: UPDATE_USER,
  payload: { id, user, history },
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})
