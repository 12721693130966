import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  ADD_NEW_PRODUCT,
  ADD_NEW_VARIATION,
  DELETE_PRODUCT,
  DELETE_VARIATION,
  GET_CATEGORY_IN_PRODUCT,
  GET_COLOR_IN_VARIATION,
  GET_PRODUCTS_LIST,
  GET_PRODUCT_DETAIL,
  GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM,
  GET_SIZE_CHART_IN_PRODUCT,
  GET_SIZE_IN_PRODUCT,
  GET_SUBCATEGORY_IN_PRODUCT,
  GET_VARIATION_LIST,
  UPDATE_PRODUCT,
} from "./actionTypes"
import {
  addNewProductFail,
  addNewProductSuccess,
  addNewVariationFail,
  addNewVariationSuccess,
  deleteProductFail,
  deleteProductSuccess,
  deleteVariationFail,
  deleteVariationSuccess,
  getCategoryInProductFail,
  getCategoryInProductSuccess,
  getColorInVariationFail,
  getColorInVariationSuccess,
  getProductDetailFail,
  getProductDetailSuccess,
  getProductsListFail,
  getProductsListSuccess,
  getSizeChartInProductFail,
  getSizeChartInProductSuccess,
  getSizeInProductFail,
  getSizeInProductSuccess,
  getSizesForSizeChartByTopOrBottomFail,
  getSizesForSizeChartByTopOrBottomSuccess,
  getSubcategoryInProductFail,
  getSubcategoryInProductSuccess,
  getVariationListFail,
  getVariationListSuccess,
  updateProductFail,
  updateProductSuccess,
} from "./actions"
import {
  addNewProduct,
  addNewVariation,
  deleteProduct,
  deleteVariation,
  getCategoryInProduct,
  getColorInVariation,
  getProductDetails,
  getProductsList,
  getSizeChartInProduct,
  getSizeInProduct,
  getSizesForSizeChartByTopOrBottom,
  getSubcategoryInProduct,
  getVariationList,
  updateProduct,
} from "helpers/fakebackend_helper"
import { toast } from "react-toastify"
import Swal from "sweetalert2"

//Include Both Helper File with needed methods

function* fetchProducts() {
  try {
    const response = yield call(getProductsList)
    yield put(getProductsListSuccess(response))
  } catch (error) {
    yield put(getProductsListFail(error))
  }
}

function* fetchProductDetail({ productId }) {
  try {
    const response = yield call(getProductDetails, productId)
    yield put(getProductDetailSuccess(response))
  } catch (error) {
    yield put(getProductDetailFail(error))
  }
}

function* onAddNewProduct({ payload: { product, history } }) {
  try {
    const response = yield call(addNewProduct, product)
    yield put(addNewProductSuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Product has been added successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history("/list-products")
        }
      })
    }
  } catch (error) {
    yield put(addNewProductFail(error))
    toast.error(error?.response?.data?.msg, { autoClose: 2000 })
  }
}

function* onUpdateProduct({ payload: { id, product, history } }) {
  try {
    const response = yield call(updateProduct, id, product, history)
    yield put(updateProductSuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Product has been updated successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history("/list-products")
        }
      })
    }
  } catch (error) {
    yield put(updateProductFail(error))
    toast.error("Product Update Failed", { autoClose: 2000 })
  }
}

function* onDeleteProduct({ product }) {
  try {
    const response = yield call(deleteProduct, product)
    yield put(deleteProductSuccess(response))
    const response2 = yield call(getProductsList)
    yield put(getProductsListSuccess(response2))
    toast.success("Product Deleted Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteProductFail(error))
    toast.error("Product Delete Failed", { autoClose: 2000 })
  }
}

function* fetchCategoryInProduct() {
  try {
    const response = yield call(getCategoryInProduct)
    yield put(getCategoryInProductSuccess(response))
  } catch (error) {
    yield put(getCategoryInProductFail(error))
  }
}

function* fetchSizeInProduct() {
  try {
    const response = yield call(getSizeInProduct)
    yield put(getSizeInProductSuccess(response))
  } catch (error) {
    yield put(getSizeInProductFail(error))
  }
}

function* fetchSizeChartInProduct() {
  try {
    const response = yield call(getSizeChartInProduct)
    yield put(getSizeChartInProductSuccess(response))
  } catch (error) {
    yield put(getSizeChartInProductFail(error))
  }
}

function* fetchSubcategoryInProduct({ categoryId }) {
  try {
    const response = yield call(getSubcategoryInProduct, categoryId)
    yield put(getSubcategoryInProductSuccess(response))
  } catch (error) {
    yield put(getSubcategoryInProductFail(error))
  }
}

function* fetchColorInVariation() {
  try {
    const response = yield call(getColorInVariation)
    yield put(getColorInVariationSuccess(response))
  } catch (error) {
    yield put(getColorInVariationFail(error))
  }
}

function* fetchVariationList({ productId }) {
  try {
    const response = yield call(getVariationList, productId)
    yield put(getVariationListSuccess(response))
  } catch (error) {
    yield put(getVariationListFail(error))
  }
}

function* onDeleteVariation({ payload: { id, variation } }) {
  try {
    const response = yield call(deleteVariation, id, variation)
    yield put(deleteVariationSuccess(response))
    const response2 = yield call(getVariationList, id)
    yield put(getVariationListSuccess(response2))
    toast.success("Variation Deleted Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteVariationFail(error))
    toast.error("Variation Delete Failed", { autoClose: 2000 })
  }
}

function* onAddNewVariation({ payload: { id, product, history } }) {
  try {
    const response = yield call(addNewVariation, id, product)
    yield put(addNewVariationSuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Variation has been added successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history(`/list-variation/${id}`)
        }
      })
    }
  } catch (error) {
    yield put(addNewVariationFail(error))
    toast.error(error?.response?.data?.msg, { autoClose: 2000 })
  }
}

function* fetchSizesForSizeChartByTopOrBottom({ data }) {
  try {
    const response = yield call(getSizesForSizeChartByTopOrBottom, data)
    yield put(getSizesForSizeChartByTopOrBottomSuccess(response))
  } catch (error) {
    yield put(getSizesForSizeChartByTopOrBottomFail(error))
  }
}

function* productSaga() {
  yield takeEvery(GET_PRODUCTS_LIST, fetchProducts)
  yield takeEvery(GET_PRODUCT_DETAIL, fetchProductDetail)
  yield takeEvery(ADD_NEW_PRODUCT, onAddNewProduct)
  yield takeEvery(UPDATE_PRODUCT, onUpdateProduct)
  yield takeEvery(DELETE_PRODUCT, onDeleteProduct)
  yield takeEvery(GET_CATEGORY_IN_PRODUCT, fetchCategoryInProduct)
  yield takeEvery(GET_SIZE_IN_PRODUCT, fetchSizeInProduct)
  yield takeEvery(GET_SIZE_CHART_IN_PRODUCT, fetchSizeChartInProduct)
  yield takeEvery(GET_SUBCATEGORY_IN_PRODUCT, fetchSubcategoryInProduct)
  yield takeEvery(GET_COLOR_IN_VARIATION, fetchColorInVariation)
  yield takeEvery(GET_VARIATION_LIST, fetchVariationList)
  yield takeEvery(DELETE_VARIATION, onDeleteVariation)
  yield takeEvery(ADD_NEW_VARIATION, onAddNewVariation)
  yield takeEvery(
    GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM,
    fetchSizesForSizeChartByTopOrBottom
  )
}

export default productSaga
