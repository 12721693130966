export const GET_SUBCATEGORIES_LIST = "GET_SUBCATEGORIES_LIST"
export const GET_SUBCATEGORIES_LIST_SUCCESS = "GET_SUBCATEGORIES_LIST_SUCCESS"
export const GET_SUBCATEGORIES_LIST_FAIL = "GET_SUBCATEGORIES_LIST_FAIL"

export const GET_SUBCATEGORY_DETAIL = "GET_SUBCATEGORY_DETAIL"
export const GET_SUBCATEGORY_DETAIL_SUCCESS = "GET_SUBCATEGORY_DETAIL_SUCCESS"
export const GET_SUBCATEGORY_DETAIL_FAIL = "GET_SUBCATEGORY_DETAIL_FAIL"

export const ADD_NEW_SUBCATEGORY = "ADD_NEW_SUBCATEGORY"
export const ADD_NEW_SUBCATEGORY_SUCCESS = "ADD_NEW_SUBCATEGORY_SUCCESS"
export const ADD_NEW_SUBCATEGORY_FAIL = "ADD_NEW_SUBCATEGORY_FAIL"

export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY"
export const UPDATE_SUBCATEGORY_SUCCESS = "UPDATE_SUBCATEGORY_SUCCESS"
export const UPDATE_SUBCATEGORY_FAIL = "UPDATE_SUBCATEGORY_FAIL"

export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY"
export const DELETE_SUBCATEGORY_SUCCESS = "DELETE_SUBCATEGORY_SUCCESS"
export const DELETE_SUBCATEGORY_FAIL = "DELETE_SUBCATEGORY_FAIL"

export const GET_CATEGORIES_LIST_FOR_SUBCATEGORY = "GET_CATEGORIES_LIST_FOR_SUBCATEGORY"
export const GET_CATEGORIES_LIST_FOR_SUBCATEGORY_SUCCESS = "GET_CATEGORIES_LIST_FOR_SUBCATEGORY_SUCCESS"
export const GET_CATEGORIES_LIST_FOR_SUBCATEGORY_FAIL = "GET_CATEGORIES_LIST_FOR_SUBCATEGORY_FAIL"
