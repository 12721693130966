import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  ADD_NEW_SUBCATEGORY,
  DELETE_SUBCATEGORY,
  GET_SUBCATEGORIES_LIST,
  GET_SUBCATEGORY_DETAIL,
  UPDATE_SUBCATEGORY,
  GET_CATEGORIES_LIST_FOR_SUBCATEGORY,
} from "./actionTypes"
import {
  addNewSubCategoryFail,
  addNewSubCategorySuccess,
  deleteSubCategoryFail,
  deleteSubCategorySuccess,
  getCategoriesListForSubCategoryFail,
  getCategoriesListForSubCategorySuccess,
  getSubCategoriesListFail,
  getSubCategoriesListSuccess,
  getSubCategoryDetailFail,
  getSubCategoryDetailSuccess,
  updateSubCategoryFail,
  updateSubCategorySuccess,
} from "./actions"
import {
  addNewSubCategory,
  deleteSubCategory,
  getCategoriesListForSubCategory,
  getSubCategoriesList,
  getSubCategoryDetails,
  updateSubCategory,
} from "helpers/fakebackend_helper"
import { toast } from "react-toastify"
import Swal from "sweetalert2"

//Include Both Helper File with needed methods

function* fetchSubCategories() {
  try {
    const response = yield call(getSubCategoriesList)
    yield put(getSubCategoriesListSuccess(response))
  } catch (error) {
    yield put(getSubCategoriesListFail(error))
  }
}

function* fetchSubCategoryDetail({ categoryId }) {
  try {
    const response = yield call(getSubCategoryDetails, categoryId)
    yield put(getSubCategoryDetailSuccess(response))
  } catch (error) {
    yield put(getSubCategoryDetailFail(error))
  }
}

function* onAddNewSubCategory({ payload: { category, history } }) {
  try {
    const response = yield call(addNewSubCategory, category)
    yield put(addNewSubCategorySuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Subcategory has been added successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history("/list-subcategories")
        }
      })
    }
  } catch (error) {
    yield put(addNewSubCategoryFail(error))
    toast.error(error?.response?.data?.msg, { autoClose: 2000 })
  }
}

function* onUpdateSubCategory({ payload: { id, category, history } }) {
  try {
    const response = yield call(updateSubCategory, id, category)
    yield put(updateSubCategorySuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Subcategory has been updated successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history("/list-subcategories")
        }
      })
    }
  } catch (error) {
    yield put(updateSubCategoryFail(error))
    toast.error("SubCategory Update Failed", { autoClose: 2000 })
  }
}

function* onDeleteSubCategory({ category }) {
  try {
    const response = yield call(deleteSubCategory, category)
    yield put(deleteSubCategorySuccess(response))
    const response2 = yield call(getSubCategoriesList)
    yield put(getSubCategoriesListSuccess(response2))
    toast.success("SubCategory Deleted Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteSubCategoryFail(error))
    toast.error("SubCategory Delete Failed", { autoClose: 2000 })
  }
}

function* fetchCategoriesForSubCategory() {
  try {
    const response = yield call(getCategoriesListForSubCategory)
    yield put(getCategoriesListForSubCategorySuccess(response))
  } catch (error) {
    yield put(getCategoriesListForSubCategoryFail(error))
  }
}

function* subcategorySaga() {
  yield takeEvery(GET_SUBCATEGORIES_LIST, fetchSubCategories)
  yield takeEvery(GET_SUBCATEGORY_DETAIL, fetchSubCategoryDetail)
  yield takeEvery(ADD_NEW_SUBCATEGORY, onAddNewSubCategory)
  yield takeEvery(UPDATE_SUBCATEGORY, onUpdateSubCategory)
  yield takeEvery(DELETE_SUBCATEGORY, onDeleteSubCategory)
  yield takeEvery(
    GET_CATEGORIES_LIST_FOR_SUBCATEGORY,
    fetchCategoriesForSubCategory
  )
}

export default subcategorySaga
