import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { Card, Col, CardBody } from "reactstrap"
import { getCountForDashboard } from "store/actions"

const typeOfRequest = props => {
  // const countState = state => state.Dashboard.count
  // const count = useSelector(countState) || []
  const count = props.count || []

  // const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(getCountForDashboard())
  // }, [dispatch])

  return (
    <React.Fragment>
      {/* {(blodStatsData || []).map((stat, index) => ( */}
      {/* <Col lg={4} key={index}> */}
      <Col lg={3}>
        <Card className="blog-stats-wid">
          <Link to="/list-products">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Products</p>
                  <h5 className="mb-0  text-primary ">
                    {count && count.totalProducts}
                  </h5>
                </div>
                <div className="avatar-sm ms-auto">
                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <i className="mdi mdi-shopping"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Link>
        </Card>
      </Col>
      <Col lg={3}>
        <Card className="blog-stats-wid">
          <Link to="/customers">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Customers</p>
                  <h5 className="mb-0 text-warning">
                    {count && count.totalCustomers}
                  </h5>
                </div>
                <div className="avatar-sm ms-auto">
                  <div className="avatar-title bg-light rounded-circle text-warning font-size-20">
                    <i className="mdi mdi-account-group"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Link>
        </Card>
      </Col>
      <Col lg={3}>
        <Card className="blog-stats-wid">
          <Link to="/orders">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2 ">Orders Received</p>
                  <h5 className="mb-0 text-danger">
                    {count && count.totalOrders}
                  </h5>
                </div>
                <div className="avatar-sm ms-auto">
                  <div className="avatar-title bg-light rounded-circle text-danger font-size-20">
                    <i className="mdi mdi-shopping-outline"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Link>
        </Card>
      </Col>
      <Col lg={3}>
        <Card className="blog-stats-wid">
          <Link to="/orders-delivered">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Orders Delivered</p>
                  <h5 className="mb-0 text-success">
                    {count && count.deliveredOrders}
                  </h5>
                </div>
                <div className="avatar-sm ms-auto">
                  <div className="avatar-title bg-light rounded-circle text-success font-size-20">
                    <i className="mdi mdi-shopping-outline"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Link>
        </Card>
      </Col>
      {/* ))} */}
    </React.Fragment>
  )
}

export default typeOfRequest
