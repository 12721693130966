import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import AddProduct from "pages/Product/AddProduct/AddProduct"
import EditProduct from "pages/Product/EditProduct/EditProduct"
import AddColor from "pages/Color/AddColor/AddColor"
import AddSize from "pages/Size/AddSize/AddSize"
import Category from "pages/Category/AddCategory/AddCategory"
import CategoriesList from "pages/Category/CategoriesList/categoriesList"
import EditCategory from "pages/Category/EditCartegory/EditCategory"
import AddUser from "pages/User/AddUser/AddUser"
import EditUser from "pages/User/EditUser/EditUser"
import UsersList from "pages/User/UserList/usersList"
import ProductList from "pages/Product/ProductList/productsList"
import ColorList from "pages/Color/ColorList/colorsList"
import SizesList from "pages/Size/SizeList/sizeList"
import EditColor from "pages/Color/EditColor/EditColor"
import EditSize from "pages/Size/EditSize/EditSize"
import AddSubCategory from "pages/SubCategory/AddSubCategory/AddSubCategory"
import EditSubCategory from "pages/SubCategory/EditSubCartegory/EditSubCategory"
import SubcategoriesList from "pages/SubCategory/SubCategoriesList/SubcategoriesList"
import SizeChartList from "pages/SizeChart/SizeChartList/sizeChartList"
import AddSizeChart from "pages/SizeChart/AddSizeChart/AddSizeChart"
import EditSizeChart from "pages/SizeChart/EditSizeChart/EditSizeChart"
import AddVariation from "pages/Variation/AddVariation/AddVariation"
import ListVariation from "pages/Variation/ListVariation/ListVariation"
import NewsLetterList from "pages/NewsLetter/NewsLetterList/newsLetterList"
import EcommerceUsersList from "pages/E-Commece Users/E-Commerce Users List/EcommerceusersList"
import OrdersList from "pages/Orders/OrderList/OrderList"
import DeliveredOrderList from "pages/Orders/OrderList/deliveredOrderList"
import OrderDetail from "pages/Orders/OrderDetail/orderDetail"
import ContactList from "pages/ContactUs/ContactUsList/contactUsList"
import EcommerceUserDetail from "pages/E-Commece Users/E-Commerce Users Detail/ecommerceUserDetail"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/add-product", component: <AddProduct /> },
  { path: "/edit-product/:id", component: <EditProduct /> },
  { path: "/list-products", component: <ProductList /> },
  { path: "/add-color", component: <AddColor /> },
  { path: "/edit-color/:id", component: <EditColor /> },
  { path: "/list-colors", component: <ColorList /> },
  { path: "/add-size", component: <AddSize /> },
  { path: "/list-sizes", component: <SizesList /> },
  { path: "/edit-size/:id", component: <EditSize /> },
  { path: "/add-user", component: <AddUser /> },
  { path: "/edit-user/:id", component: <EditUser /> },
  { path: "/list-users", component: <UsersList /> },
  { path: "/add-category", component: <Category /> },
  { path: "/edit-category/:id", component: <EditCategory /> },
  { path: "/list-categories", component: <CategoriesList /> },
  { path: "/add-subcategory", component: <AddSubCategory /> },
  { path: "/edit-subcategory/:id", component: <EditSubCategory /> },
  { path: "/list-subcategories", component: <SubcategoriesList /> },
  { path: "/add-size-chart", component: <AddSizeChart /> },
  { path: "/edit-size-chart/:id", component: <EditSizeChart /> },
  { path: "/list-size-chart", component: <SizeChartList /> },
  { path: "/list-variation/:id", component: <ListVariation /> },
  { path: "/add-variation/:id", component: <AddVariation /> },
  { path: "/news-letter-subscribers", component: <NewsLetterList /> },
  { path: "/customers", component: <EcommerceUsersList /> },
  { path: "/orders", component: <OrdersList /> },
  { path: "/orders-delivered", component: <DeliveredOrderList /> },
  { path: "/order-detail/:id", component: <OrderDetail /> },
  { path: "/contact-requests", component: <ContactList /> },
  { path: "/customer-detail/:id", component: <EcommerceUserDetail /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
]

export { authProtectedRoutes, publicRoutes }
