import formatNumberWithCommas from "components/Common/formatNumberWithCommas"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Modal, Table } from "reactstrap"
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"
import { billAmountList } from "store/actions"

const newProduct = ({ isOpen, toggle, data }) => {
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Products
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <SimpleBar style={{ maxHeight: "60vh", overflowX: "auto" }}>
          <div style={{ minWidth: "100%" }}>
            <Table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product Name</th>
                  <th>Gender</th>
                  <th>Pockets</th>
                  <th>Regular Price</th>
                </tr>
              </thead>
              <tbody>
                {(data || []).map((row, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{row.product_name}</td>
                    <td>{row.gender}</td>
                    <td>{row.pockets}</td>
                    <td>₹{formatNumberWithCommas(row.regular_price)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </SimpleBar>
      </div>
    </Modal>
  )
}

export default newProduct
