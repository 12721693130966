import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_CONTACT_US_LIST } from "./actionTypes"
import { getContactUsListFail, getContactUsListSuccess } from "./actions"
import { getContactUsList } from "helpers/fakebackend_helper"

//Include Both Helper File with needed methods

function* fetchContactUsList() {
  try {
    const response = yield call(getContactUsList)
    yield put(getContactUsListSuccess(response))
  } catch (error) {
    yield put(getContactUsListFail(error))
  }
}

function* contactUsSaga() {
  yield takeEvery(GET_CONTACT_US_LIST, fetchContactUsList)
}

export default contactUsSaga
