import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_ECOM_USERS_LIST } from "./actionTypes"
import { getEcomUsersListFail, getEcomUsersListSuccess } from "./actions"
import { getEcomUsersList } from "helpers/fakebackend_helper"

//Include Both Helper File with needed methods

function* fetchUsers({ id }) {
  try {
    const response = yield call(getEcomUsersList, id)
    yield put(getEcomUsersListSuccess(response))
  } catch (error) {
    yield put(getEcomUsersListFail(error))
  }
}

function* ecomusersSaga() {
  yield takeEvery(GET_ECOM_USERS_LIST, fetchUsers)
}

export default ecomusersSaga
