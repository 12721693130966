import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { ColorPicker, useColor } from "react-color-palette"
import "react-color-palette/css"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useDispatch } from "react-redux"
import { getEcomUsersList } from "store/actions"
import { useSelector } from "react-redux"
import { API_URL } from "helpers/url_helper"

const EcomUserDetail = props => {
  const dispatch = useDispatch()
  let { id } = useParams()

  useEffect(() => {
    dispatch(getEcomUsersList(id))
  }, [dispatch])

  const dataState = state => state.ecomusers.userList.data
  const data = useSelector(dataState) || []

  document.title = "Customer Detail"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CardTitle className="mb-2 font-size-18">
            <Link to="/customers" style={{ color: "black" }}>
              <i className="mdi mdi-arrow-left font-size-18 me-2"></i>
            </Link>
            Customer Detail
          </CardTitle>
          {data &&
            data.map((customer, index) => (
              <Row key={index} style={{ marginBottom: "20px" }}>
                <Col xs="12">
                  <Card style={{ boxShadow: "0 0 10px rgba(0,0,0,0.1)" }}>
                    <CardBody>
                      <Row>
                        <Col lg={6} style={{ paddingRight: "20px" }}>
                          <h5
                            style={{
                              marginBottom: "1rem",
                              fontWeight: "bold",
                              fontSize: "18px",
                            }}
                          >
                            Full Name: {customer.full_name}
                          </h5>
                          <p style={{ marginBottom: "0.5rem" }}>
                            <strong>Username:</strong> {customer.username}
                          </p>
                          <p style={{ marginBottom: "0.5rem" }}>
                            <strong>Email:</strong> {customer.email}
                          </p>
                          <p style={{ marginBottom: "0.5rem" }}>
                            <strong>Phone:</strong> {customer.phone_no}
                          </p>
                          <p style={{ marginBottom: "0.5rem" }}>
                            <strong>Status:</strong>{" "}
                            {customer.is_active ? "Active" : "Inactive"}
                          </p>
                          <p style={{ marginBottom: "0.5rem" }}>
                            <strong>Created At:</strong>{" "}
                            {new Date(customer.createdAt).toLocaleDateString()}
                          </p>
                          <p style={{ marginBottom: "0.5rem" }}>
                            <strong>Updated At:</strong>{" "}
                            {new Date(customer.updatedAt).toLocaleDateString()}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div style={{ marginTop: "2rem" }}>
                            <h5
                              style={{
                                marginBottom: "1rem",
                                fontWeight: "bold",
                                fontSize: "18px",
                              }}
                            >
                              Addresses
                            </h5>
                            {customer.address.map((address, idx) => (
                              <div
                                key={idx}
                                style={{
                                  marginBottom: "1rem",
                                  paddingLeft: "20px",
                                  borderLeft: "2px solid #ccc",
                                  padding: "10px",
                                  borderRadius: "10px",
                                  backgroundColor: "#f7f7f7",
                                }}
                              >
                                <p style={{ marginBottom: "0.5rem" }}>
                                  <strong>Address Line 1:</strong>{" "}
                                  {address.address_line_1}
                                </p>
                                <p style={{ marginBottom: "0.5rem" }}>
                                  <strong>Address Line 2:</strong>{" "}
                                  {address.address_line_2}
                                </p>
                                <p style={{ marginBottom: "0.5rem" }}>
                                  <strong>Address Line 3:</strong>{" "}
                                  {address.address_line_3}
                                </p>
                                <p style={{ marginBottom: "0.5rem" }}>
                                  <strong>Pin Code:</strong> {address.pin_code}
                                </p>
                                <p style={{ marginBottom: "0.5rem" }}>
                                  <strong>State:</strong> {address.state}
                                </p>
                                <p style={{ marginBottom: "0.5rem" }}>
                                  <strong>Country:</strong> {address.country}
                                </p>
                                {address.companyDetail === true && (
                                  <>
                                    <p style={{ marginBottom: "0.5rem" }}>
                                      <strong>Company Name:</strong>{" "}
                                      {address.companyName}
                                    </p>
                                    <p style={{ marginBottom: "0.5rem" }}>
                                      <strong>GST Number:</strong>{" "}
                                      {address.gstNo}
                                    </p>
                                  </>
                                )}
                              </div>
                            ))}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ))}
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(EcomUserDetail)

EcomUserDetail.propTypes = {
  history: PropTypes.object,
}
