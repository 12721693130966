import {
  ADD_NEW_PRODUCT_SUCCESS,
  ADD_NEW_PRODUCT_FAIL,
  GET_PRODUCTS_LIST_SUCCESS,
  GET_PRODUCTS_LIST_FAIL,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  GET_CATEGORY_IN_PRODUCT_SUCCESS,
  GET_CATEGORY_IN_PRODUCT_FAIL,
  GET_SUBCATEGORY_IN_PRODUCT_SUCCESS,
  GET_SUBCATEGORY_IN_PRODUCT_FAIL,
  GET_SIZE_IN_PRODUCT_SUCCESS,
  GET_SIZE_IN_PRODUCT_FAIL,
  GET_SIZE_CHART_IN_PRODUCT_SUCCESS,
  GET_SIZE_CHART_IN_PRODUCT_FAIL,
  GET_COLOR_IN_VARIATION_SUCCESS,
  GET_COLOR_IN_VARIATION_FAIL,
  GET_VARIATION_LIST_SUCCESS,
  GET_VARIATION_LIST_FAIL,
  DELETE_VARIATION_SUCCESS,
  DELETE_VARIATION_FAIL,
  ADD_NEW_VARIATION_SUCCESS,
  ADD_NEW_VARIATION_FAIL,
  GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM_SUCCESS,
  GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM_FAIL,
} from "./actionTypes"

const initialState = {
  productList: [],
  products: [],
  categories: [],
  subcategories: [],
  sizes: [],
  sizeCharts: [],
  variationList: [],
  color: [],
  productDetail: {},
  error: {},
  loading: false,
}

const products = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        productList: action.payload,
        loading: false,
      }

    case GET_PRODUCTS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        productDetail: action.payload,
        loading: false,
      }

    case GET_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NEW_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...state.products, action.payload],
        loading: false,
      }

    case ADD_NEW_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        productDetail: {
          ...state.productDetail,
          data: {
            ...state.productDetail.data,
            [action.payload.data._id]: {
              ...state.productDetail.data[action.payload.data._id],
              ...action.payload.data,
            },
          },
        },
      }

    case UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        productList: state.productList.data.filter(
          product => product._id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CATEGORY_IN_PRODUCT_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      }

    case GET_CATEGORY_IN_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SUBCATEGORY_IN_PRODUCT_SUCCESS:
      return {
        ...state,
        subcategories: action.payload,
        loading: false,
      }

    case GET_SUBCATEGORY_IN_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SIZE_IN_PRODUCT_SUCCESS:
      return {
        ...state,
        sizes: action.payload,
        loading: false,
      }

    case GET_SIZE_IN_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SIZE_CHART_IN_PRODUCT_SUCCESS:
      return {
        ...state,
        sizeCharts: action.payload,
        loading: false,
      }

    case GET_SIZE_CHART_IN_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COLOR_IN_VARIATION_SUCCESS:
      return {
        ...state,
        color: action.payload,
        loading: false,
      }

    case GET_COLOR_IN_VARIATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_VARIATION_LIST_SUCCESS:
      return {
        ...state,
        variationList: action.payload,
        loading: false,
      }

    case GET_VARIATION_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_VARIATION_SUCCESS:
      return {
        ...state,
        variationList: {
          ...state.variationList,
          data: state.variationList.data.filter(
            variation =>
              variation._id.toString() !== action.payload.data._id.toString()
          ),
        },
      }

    case DELETE_VARIATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NEW_VARIATION_SUCCESS:
      return {
        ...state,
        products: [...state.products, action.payload],
        loading: false,
      }

    case ADD_NEW_VARIATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM_SUCCESS:
      return {
        ...state,
        sizeCharts: action.payload,
        loading: false,
      }

    case GET_SIZES_FOR_SIZE_CHART_BY_TOP_OR_BOTTOM_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default products
