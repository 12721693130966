import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import { addNewUser, getUserDetail, updateUser } from "store/actions"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

const EditUser = props => {
  document.title = "Edit Admin User"
  const dispatch = useDispatch()
  let { id } = useParams()

  useEffect(() => {
    dispatch(getUserDetail(id))
  }, [dispatch])

  const dataState = state => state.Users.userDetail.data
  const data = useSelector(dataState) || []

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      full_name: (data && data.full_name) || "",
      phone_no: (data && data.phone_no) || "",
      email: (data && data.email) || "",
      username: (data && data.username) || "",
      designation: (data && data.designation) || "",
    },

    validationSchema: Yup.object({
      full_name: Yup.string().required("Please Enter Full Name"),
      phone_no: Yup.string()
        .matches(
          /^[0-9]{10,12}$/,
          "Phone Number must be between 10 to 12 digits"
        )
        .required("Please Enter Phone Number "),
      email: Yup.string().email().required("Please Enter Email"),
      username: Yup.string().required("Please Enter Username"),
      designation: Yup.string().required("Please Enter Designation"),
    }),

    onSubmit: async values => {
      dispatch(updateUser(id, values, props.router.navigate))
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CardTitle className="mb-2 font-size-18">
            <Link to="/list-users" style={{ color: "black" }}>
              <i className="mdi mdi-arrow-left font-size-18 me-2"></i>
            </Link>
            Edit Admin User
          </CardTitle>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    {" "}
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="size">Full Name*</Label>
                          <Input
                            id="full_name"
                            name="full_name"
                            type="text"
                            className="form-control"
                            placeholder="Enter Full Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.full_name &&
                              validation.errors.full_name
                                ? true
                                : false
                            }
                            value={validation.values.full_name}
                          />
                          {validation.touched.full_name &&
                          validation.errors.full_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.full_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Phone Number*</Label>
                          <Input
                            id="phone_no"
                            name="phone_no"
                            type="text"
                            className="form-control"
                            placeholder="Enter Phone Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.phone_no &&
                              validation.errors.phone_no
                                ? true
                                : false
                            }
                            value={validation.values.phone_no}
                          />
                          {validation.touched.phone_no &&
                          validation.errors.phone_no ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone_no}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="size">Email*</Label>
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Enter Email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                            value={validation.values.email}
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Username*</Label>
                          <Input
                            id="username"
                            name="username"
                            type="text"
                            className="form-control"
                            placeholder="Enter Username"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.username &&
                              validation.errors.username
                                ? true
                                : false
                            }
                            value={validation.values.username}
                          />
                          {validation.touched.username &&
                          validation.errors.username ? (
                            <FormFeedback type="invalid">
                              {validation.errors.username}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Designation*</Label>
                          <Input
                            id="designation"
                            name="designation"
                            type="text"
                            className="form-control"
                            placeholder="Enter Designation"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.designation &&
                              validation.errors.designation
                                ? true
                                : false
                            }
                            value={validation.values.designation}
                          />
                          {validation.touched.designation &&
                          validation.errors.designation ? (
                            <FormFeedback type="invalid">
                              {validation.errors.designation}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-center mt-3">
                      <Col className="text-end">
                        <button
                          className="w-md "
                          type="submit"
                          style={{
                            backgroundColor: "#082a6b",
                            color: "#ffffff",
                            borderTopRightRadius: "10px",
                            borderBottomLeftRadius: "10px",
                            height: "35px",
                          }}
                          onSubmit={validation.handleSubmit}
                        >
                          Edit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(EditUser)

EditUser.propTypes = {
  history: PropTypes.object,
}
