import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"
import { login, logout } from "helpers/fakebackend_helper"
import { toast } from "react-toastify"

//Include Both Helper File with needed methods

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(login, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      history("/dashboard")
    }
  } catch (error) {
    yield put(apiError(error))
    toast.error(error?.response?.data?.msg, { autoClose: 2000 })
  }
}

function* logoutUser({ payload: { userId, history } }) {
  try {
    const response = yield call(logout, { _id: userId })
    if (response.status === true) {
      localStorage.removeItem("authUser")
    }
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
