import React, { useState, useRef, useMemo, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import TableContainer from "./ListVariationContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  CardTitle,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"

import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { deleteVariation, getVariationList } from "store/actions"

const listVariation = props => {
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    dispatch(getVariationList(id))
  }, [dispatch])

  const dataState = state => state.products.variationList.data
  const data = useSelector(dataState) || []

  document.title = "Variation List "
  const [isLoading, setLoading] = useState(false)
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">{cellProps.row.index + 1}</h5>
            </>
          )
        },
      },
      {
        Header: "Color",
        accessor: "color_id",
        filterable: true,
        Cell: cellProps => {
          const color = cellProps.row.original?.color_info?.color_hexacode
          return (
            <>
              <div className="d-flex">
                <div
                  style={{
                    backgroundColor: color,
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                    display: "inline-block",
                    marginRight: "10px",
                  }}
                ></div>
                <h5 className="font-size-14 mt-1">
                  {cellProps.row.original?.color_info?.color_name}
                </h5>
              </div>
            </>
          )
        },
      },
      {
        Header: "Images",
        accessor: "images",
        Cell: ({ cell: { value } }) => (
          <div style={{ display: "flex", gap: "5px" }}>
            {value.map((image, index) => (
              <div
                key={index}
                style={{
                  width: "80px",
                  height: "80px",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <img
                  src={image}
                  alt={`Image ${index + 1}`}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                  }}
                />
              </div>
            ))}
          </div>
        ),
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to={`/edit-subcategory/${cellProps.row.original._id}`}
                className="text-success"
              >
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  style={{ color: "#3366cc" }}
                />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original._id
                  onClickDelete(userData)
                }}
              >
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  style={{ color: "#3366cc" }}
                />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete modal
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = userData => {
    setDeleteId({ id: userData })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    const variation = {
      variationId: deleteId.id,
    }
    dispatch(deleteVariation(id, variation))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <CardTitle className="mb-2 font-size-18">
            <Link to="/list-products" style={{ color: "black" }}>
              <i className="mdi mdi-arrow-left font-size-18 me-2"></i>
            </Link>
            Variation List
          </CardTitle>
          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={data}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default listVariation
