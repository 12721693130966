import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  ADD_NEW_SIZE_CHART,
  DELETE_SIZE_CHART,
  GET_SIZES_FOR_SIZE_CHART,
  GET_SIZE_CHART_DETAIL,
  GET_SIZE_CHART_LIST,
  UPDATE_SIZE_CHART,
} from "./actionTypes"
import {
  addNewSizeChartFail,
  addNewSizeChartSuccess,
  deleteSizeChartFail,
  deleteSizeChartSuccess,
  getSizeChartDetailFail,
  getSizeChartDetailSuccess,
  getSizeChartListFail,
  getSizeChartListSuccess,
  getSizesForSizeChartFail,
  getSizesForSizeChartSuccess,
  updateSizeChartFail,
  updateSizeChartSuccess,
} from "./actions"
import {
  addNewSizeChart,
  deleteSizeChart,
  getSizeChartDetail,
  getSizeChartList,
  getSizesForSizeChart,
  updateSizeChart,
} from "helpers/fakebackend_helper"
import { toast } from "react-toastify"
import Swal from "sweetalert2"

//Include Both Helper File with needed methods

function* fetchSizeChart() {
  try {
    const response = yield call(getSizeChartList)
    yield put(getSizeChartListSuccess(response))
  } catch (error) {
    yield put(getSizeChartListFail(error))
  }
}

function* fetchSizeChartDetail({ sizeId }) {
  try {
    const response = yield call(getSizeChartDetail, sizeId)
    yield put(getSizeChartDetailSuccess(response))
  } catch (error) {
    yield put(getSizeChartDetailFail(error))
  }
}

function* onAddNewSizeChart({ payload: { size, history } }) {
  try {
    const response = yield call(addNewSizeChart, size)
    yield put(addNewSizeChartSuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Size Chart has been added successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history("/list-size-chart")
        }
      })
    }
  } catch (error) {
    yield put(addNewSizeChartFail(error))
    toast.error(error?.response?.data?.msg, { autoClose: 2000 })
  }
}

function* onUpdateSizeChart({ payload: { id, size, history } }) {
  try {
    const response = yield call(updateSizeChart, id, size)
    yield put(updateSizeChartSuccess(response))
    if (response.result === 1) {
      Swal.fire({
        title: "Success",
        icon: "success",
        text: "Your Size Chart has been updated successfully!",
      }).then(result => {
        if (result.isConfirmed) {
          history("/list-size-chart")
        }
      })
    }
  } catch (error) {
    yield put(updateSizeChartFail(error))
    toast.error("Size Chart Update Failed", { autoClose: 2000 })
  }
}

function* onDeleteSizeChart({ size }) {
  try {
    const response = yield call(deleteSizeChart, size)
    yield put(deleteSizeChartSuccess(response))
    const response2 = yield call(getSizeChartList)
    yield put(getSizeChartListSuccess(response2))
    toast.success("Size Chart Deleted Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteSizeChartFail(error))
    toast.error("Size Chart Delete Failed", { autoClose: 2000 })
  }
}

function* fetchSizesForSizeChart() {
  try {
    const response = yield call(getSizesForSizeChart)
    yield put(getSizesForSizeChartSuccess(response))
  } catch (error) {
    yield put(getSizesForSizeChartFail(error))
  }
}

function* sizeChartSaga() {
  yield takeEvery(GET_SIZE_CHART_LIST, fetchSizeChart)
  yield takeEvery(GET_SIZE_CHART_DETAIL, fetchSizeChartDetail)
  yield takeEvery(ADD_NEW_SIZE_CHART, onAddNewSizeChart)
  yield takeEvery(UPDATE_SIZE_CHART, onUpdateSizeChart)
  yield takeEvery(DELETE_SIZE_CHART, onDeleteSizeChart)
  yield takeEvery(GET_SIZES_FOR_SIZE_CHART, fetchSizesForSizeChart)
}

export default sizeChartSaga
